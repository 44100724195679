import React, { useState } from 'react';
import arrow from "../../assets/images/tabler_chevron-up.webp";
const FAQSection = () => {
    const questions = [
        { 
            name: "Radios", 
            questions: [
                "What is soundscape radio?", 
                "What type of radio stations are available on Soundscape?", 
                "Are all these radio stations free to access?"
            ], 
            answers: [
                "Soundscape Radio is a feature provided by the soundscape app that has continuous streaming of music.", 
                `Soundscape offers two radio stations English and Hindi with different genres such as Super Cool, Classic, Radio Masti and Hit Radio.`,
                `Yes, all radio stations on Soundscape are free to enjoy.`
            ] 
        },
        // { 
        //     name: "Sleep", 
        //     questions: [
        //         "How to sleep better?", 
        //         "How do you find rain sounds for sleeping?", 
        //         "Can sleep stories help you to sleep better?"
        //     ], 
        //     answers: [
        //         "Improving your sleep can be as simple as incorporating relaxing sleep music or practising sleep meditation. These methods help calm your mind and body, preparing you for a deeper and more restful sleep. Our app offers a variety of sleep sounds, including deep sleep music, that are designed to create the perfect environment for a peaceful night’s rest. By building a bedtime routine that includes these sounds or a short meditation, you can improve both the quality and duration of your sleep.", 
        //         `<p>Rain sounds are a popular and effective way to create a calm environment for sleep. The gentle rhythm of rain helps block out background noise and promotes relaxation, making it easier to relax naturally.</p>
        //          <p style="margin-top:5px;">In our app, you can easily explore a range of rain sounds for sleeping. We offer soothing sleep sounds that cater to different preferences, allowing you to find the perfect rain sound to enhance your sleep experience.</p>
        //         `, 
        //         "Yes, Sleep Stories are a great way to improve your sleep. Listening to a calm, narrated story before bed helps your mind relax, making it easier to transition into sleep. Paired with gentle sleep music or rain sounds for sleep, Sleep Stories can create a cozy and soothing bedtime routine. They not only distract from daily stress but also set the tone for a restful night, making it easier to fall asleep and stay asleep longer."
        //     ] 
        // },
        // { 
        //     name:"Meditation",
        //     questions:[
        //         "How to meditate?", 
        //         "Can we do Meditation for sleep?", 
        //         "Are there any free meditation apps available?"
        //     ], 
        //     answers:[
        //         "Meditation is a practice that involves focusing your mind to achieve mental clarity and relaxation. Start by finding a quiet space, sit comfortably, and focus on your breath. You can also use meditation music or meditation sounds to create a calming atmosphere. If you're new to meditation, following a guided meditation session can be helpful, as it walks you through the process step by step. Incorporating these practices into your routine can bring peace and focus to your daily life.", 
        //         `Yes, meditation for sleep is effective in promoting better rest. Here’s how you can incorporate it:
        //                        <ul style="list-style-type: disc;  margin-left:30px; margin-top:10px;">
        //             <li>Guided sleep meditation: Follow along with calming narrations that guide you through relaxing techniques.</li>
        //             <li>Sleep meditation music: Pair your meditation with soft, soothing music to create a peaceful environment.</li>
        //             <li>Breathing exercises: Focus on deep, slow breathing to help you relax and let go of daily stress.</li>
        //             <li>Visualization techniques: Picture calming scenes, like a peaceful beach or forest, as part of your sleep meditation routine.</li>
        //         </ul>`, 
        //         `<p>Yes, there are plenty of free meditation apps that offer a variety of features, including guided meditation sessions and meditation music. Some of the best meditation apps provide free content, ranging from basic breathing exercises to guided meditation for sleep. </p>
        //         <p style="margin-top:5px;">Our app Soundscape also offers a range of meditation sounds and calming sleep meditation music to help you wind down and rest better, combining soothing soundscapes with guided meditation practices for an enhanced experience.</p>`
        //     ] 
        // },
        // { 
        //     name:"Stress",
        //     questions:[
        //         "What is stress?", 
        //         "How to reduce stress?", 
        //         "How to prevent anxiety and stress?"
        //     ], 
        //     answers:[
        //         "Stress is your body’s response to challenges or pressures, whether physical, mental, or emotional. It can be caused by various situations like work, relationships, or daily demands. While a little stress can motivate you, too much of it can affect your well-being, leading to anxiety, trouble sleeping, or irritability. Using tools like soundscapes or relaxing sleep music can help ease stress by creating a calming environment that encourages relaxation.", 
        //         `Reducing stress involves creating a balance between your responsibilities and self-care. 
        //         <p>Here are some ways to manage it:</p>
        //                       <ul style="list-style-type: disc;  margin-left:30px; margin-top:10px;">
        //             <li>Practice relaxation techniques like guided meditation or sleep meditation music to calm your mind.</li>
        //             <li>Use soundscapes: Listening to ambient soundscapes or rain sounds for sleeping can help you relax after a long day.</li>
        //             <li>Exercise regularly: Physical activity releases endorphins, which improve your mood.</li>
        //             <li>Create a peaceful sleep environment with sleep sounds and deep sleep music to ensure proper rest.</li>
        //         </ul>`, 
        //         `Preventing anxiety and stress involves building healthy habits that promote mental well-being. Some tips include:
        //        <ul style="list-style-type: disc;  margin-left:30px; margin-top:10px;">
        //             <li>Practice daily meditation: Even a few minutes of guided meditation can help clear your mind and reduce anxiety.</li>
        //             <li>Set a relaxing bedtime routine: Listening to sleep meditation music or meditation sounds can help prevent nighttime anxiety and improve sleep quality.</li>
        //             <li>Use calming tools: There are plenty of calming tools available for relieving stress and anxiety. Using apps that provide calming sound effects and music can also help in relaxation. Our app Soundscape offers rain sounds for sleep and meditation music, which can create a peaceful atmosphere and prevent stress from building up.</li>
        //             <li>Stay active and connected: Regular physical activity and social interaction can also reduce the impact of stress on your life.</li>
        //         </ul>`
        //     ] 
        // },
        // { 
        //     name:"Mental Health",
        //     questions:[
        //         "Why is mental health important?", 
        //         "How to improve mental health?", 
        //         "How does soundscape help in improving mental health?"
        //     ], 
        //     answers:[
        //         `Mental health is essential because it affects every aspect of our lives:
        //        <ul style="list-style-type: disc;  margin-left:30px; margin-top:10px;">
        //             <li>Emotional well-being: Good mental health helps you manage stress, relationships, and emotions more effectively.</li>
        //             <li>Physical health: Mental well-being directly impacts your physical health. Stress and anxiety can lead to issues like fatigue or headaches.</li>
        //             <li>Productivity: When your mind is clear and healthy, you're more focused, creative, and able to achieve your goals.</li>
        //             <li>Quality of life: Good mental health improves your overall life satisfaction, helping you lead a balanced lifestyle and a happy life.</li>
        //         </ul>`, 
        //         `Improving mental health involves adopting healthy habits and routines. Here are some tips:
        //        <ul style="list-style-type: disc;  margin-left:30px; margin-top:10px;">
        //             <li>Practice relaxation techniques: Meditation, such as guided meditation or using meditation music, helps reduce stress and anxiety.</li>
        //             <li>Stay active: Regular exercise boosts endorphins and improves mood.</li>
        //             <li>Get enough sleep: Using soundscapes or sleep meditation music can help you achieve better sleep, which is crucial for mental well-being.</li>
        //             <li>Connect with others: Building strong relationships and talking about your feelings can positively impact your mental health.</li>
        //             <li>Use apps: Our app offers meditation sounds and sleep music to help you relax and maintain mental balance.</li>
        //         </ul>`, 
        //         `Soundscapes are powerful tools for enhancing mental health. They use calming tunes like ambient soundscapes, rain soundscapes, and nature soundscapes to create a peaceful atmosphere, which helps reduce stress and anxiety. These soundscapes promote relaxation by blocking out distracting noises, allowing your mind to focus and unwind. Incorporating soundscapes for sleeping can also improve sleep quality, which is crucial for maintaining emotional balance and mental clarity. Our app offers a variety of soundscape music designed to relieve stress, enhance focus, and improve overall mental well-being.`
        //     ] 
        // },
        // { 
        //     name:"Binaural Beats",
        //     questions:[
        //         "What are Binaural Beats?", 
        //         "What are the benefits of listening to binaural beats?", 
        //         "What can a brainwave app assist in improving mental clarity and focus?"
        //     ], 
        //     answers:[
        //         `<p>Binaural beats are sound frequencies that have been studied for their effects on relaxation, focus, and sleep. The concept was first discovered in 1839 by physicist Heinrich Wilhelm Dove, who found that when two slightly different frequencies are played into each ear, the brain perceives a third tone—called a binaural beat. This beat can influence brainwave activity, helping to create specific mental states, such as deep relaxation or heightened focus.</p>
                
        //         <p style="margin-top:5px;">Today, binaural beats are widely used in meditation and sleep therapies. Lower frequency beats, such as those in the delta brainwave range, are often used to help with sleep and relaxation, while higher frequency beats, like alpha or gamma brainwaves, can improve focus and clarity. Many apps, including our app Soundscape, incorporate binaural beats to enhance mental well-being, making them an effective tool for reducing stress and improving overall cognitive performance.</p>`,
                
        //         `Listening to binaural beats offers various benefits, especially for relaxation and focus:
        //        <ul style="list-style-type: disc;  margin-left:30px; margin-top:10px;">
        //             <li>Improved focus and clarity: Gamma brainwaves and beta brainwaves stimulated by binaural beats can enhance concentration, making it easier to focus on tasks.</li>
        //             <li>Stress reduction: Binaural beats meditation can help reduce stress and anxiety by promoting a calm mental state, especially when paired with solfeggio healing frequencies.</li>
        //             <li>Better sleep: Binaural beats for sleep, particularly at lower frequencies like alpha brainwaves, can help induce relaxation and improve sleep quality.</li>
        //             <li>Enhanced meditation: Binaural beats are often used in guided meditation or meditation music to deepen your meditation practice and bring a sense of inner peace.</li>
        //         </ul>`,
                
        //         `A brainwave app can use sound frequencies to boost mental clarity and focus in the following ways:
        //        <ul style="list-style-type: disc;  margin-left:30px; margin-top:10px;">
        //             <li>Customizable brainwave sessions: Apps provide access to various frequencies like gamma brainwaves and beta brainwaves, which are known to improve concentration and alertness.</li>
        //             <li>Focus and relaxation toolsSome brainwave apps combine binaural beats with solfeggio frequencies or ambient soundscapes to help balance relaxation with focus.</li>
        //             <li>Meditation and stress relief: By using binaural beats meditation or solfeggio healing frequencies, a brainwave app can help reduce stress and promote mental clarity.</li>
        //             <li>Sleep aid: Many apps offer binaural beats for sleep or sleep meditation music, which can improve your overall rest and mental sharpness during the day.</li>
        //             <li>Our app also provides soundscapes and meditation sounds to enhance mental clarity and reduce distractions.</li>
        //         </ul>`
        //     ] 
        // }
    ];

    // State to keep track of the currently open question
    const [openQuestion, setOpenQuestion] = useState(null);

    // Function to handle opening and closing questions
    const handleToggle = (sectionIndex, questionIndex) => {
        // If the clicked question is already open, close it; otherwise, open it
        if (openQuestion && openQuestion.sectionIndex === sectionIndex && openQuestion.questionIndex === questionIndex) {
            setOpenQuestion(null);
        } else {
            setOpenQuestion({ sectionIndex, questionIndex });
        }
    };

    return (
        <div className='text-white bg-[#1F1931]'>
            <h2 className='text-center text-[35px]  mt-32 mb-8'>Frequently Asked <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Questions</span></h2>
            <div className=' flex flex-col items-center pb-32 px-[5vw]'>
                {questions.map((q, sectionIndex) => (
                    <div key={sectionIndex} className='w-full max-w-[1200px] mb-10 '>
                        <h3 className='mb-[2vh] font-bold text-[30px] cursor-default'>{q.name}</h3>
                        {q.questions.map((question, questionIndex) => (
                            <div key={questionIndex}>
                                <hr className='border border-white border-1 border-opacity-10' />
                                <div
                                    className='flex justify-between items-center my-[2vh] text-lg cursor-pointer'
                                    onClick={() => handleToggle(sectionIndex, questionIndex)}
                                >
                                    <div className='text-start text-[#B4BCD0]'>{question}</div>
                                    <img
                                        src={arrow}
                                        alt="arrow"
                                        className={`w-6 h-4 ml-4 transition-transform duration-300 ${openQuestion && openQuestion.sectionIndex === sectionIndex && openQuestion.questionIndex === questionIndex ? 'rotate-180' : ''}`}
                                    />
                                </div>
                                {openQuestion && openQuestion.sectionIndex === sectionIndex && openQuestion.questionIndex === questionIndex && (
                                    <div 
                                        className='mb-[2vh] -mt-[1vh] text-[#B4BCD0]'
                                        dangerouslySetInnerHTML={{ __html: q.answers[questionIndex] }}
                                    />
                                )}
                                
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQSection;
