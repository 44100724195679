import React from 'react';
import musiclisten from "../../../assets/images/Group 7412 1.svg";
import { useNavigate } from 'react-router-dom';

const HomeSection = () => {
  const navigate = useNavigate();

  const handleExploreClick = () => {
    window.scrollTo(0, 0);
    navigate("/top-playlist");
  };

  return (
    <div className='flex flex-col px-[5vw] items-center'>
      <div className='max-w-[1200px] w-full'>
        <div className='sm:flex hidden justify-center items-center  mt-[65px] cursor-default'>
          <div className='w-1/2 text-white flex flex-col md:gap-[30px] gap-7  mt-[70px]'>
            <h1 className='md:text-[35px] text-[30px] '>
             <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Soundscape</span> Is Your Perfect Music Platform For A <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Balanced</span> Lifestyle</h1>

            <p className='text-[#B4BCD0] md:text-[16px] text-[16px]'>
              Enhance your rest with brainwaves and explore hundreds of guided
              meditations, soothing sleep music tracks and calming stories.
            </p>

            <p className='text-[#B4BCD0] md:text-[16px] text-[16px]'>
              All designed to help you achieve a harmonious, balanced lifestyle.
            </p>
            <button
  className='px-6 py-2 cursor-pointer md:text-[14px] md:mt-[10px] text-[14px] hover:bg-[#EEEEEE] font-bold rounded-full bg-white shadow-[inset_0_-4px_6px_rgba(0,0,0,0.6)] text-black w-fit'
  onClick={() => {
    handleExploreClick();
  }}
>
  Play Now
</button>
          </div>
          <div className='w-1/2 flex justify-end items-end mt-16'>
            <img src={musiclisten} alt="listening music"  className='w-auto h-auto'/>
          </div>
        </div>
        <div className='sm:hidden flex flex-col justify-center items-center mt-16 cursor-default'>

          <div className='flex justify-center items-center'>
            <img src={musiclisten} alt="listening music"  className='h-fit w-fit'/>
          </div>
          <div className='w-full text-white flex flex-col md:gap-10 gap-7'>
            <h1 className='md:text-[35px] text-[30px] '>
              Your Perfect Music Platform For A{" "}
              <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Balanced</span> Lifestyle
            </h1>

            <p className='text-[#B4BCD0] md:text-sm text-sm'>
              Enhance your rest with brainwaves and explore hundreds of guided
              meditations, soothing sleep music tracks and calming stories.
            </p>

            <p className='text-[#B4BCD0] md:text-sm text-sm'>
              All designed to help you achieve a harmonious, balanced lifestyle.
            </p>
            <button
  className='px-6 py-2 cursor-pointer text-[14px] hover:bg-[#EEEEEE] font-bold rounded-full bg-white shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] text-black w-fit'
  onClick={() => {
    handleExploreClick();
  }}
>
  Play Now
</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection;
