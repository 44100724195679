import React ,{useEffect,useState} from 'react'
import logo from "../assets/images/Frame 33599.png"
import Header from './Header';
import NewFooter from './NewFooter';
const PrivacyPolicy = () => {
  const [isScrolled, setIsScrolled] = useState(false);

    const customStyle = {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(3px)',
        backgroundColor: isScrolled ? '#12111B' : '',
        transition: 'background-color 0.3s ease',
    };

    useEffect(() => {
      const handleScroll = () => {
          setIsScrolled(window.scrollY > 0); 
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  return (
    <div className=''>
    <div className=' w-full  text-white text-justify bg-gradient-to-b' style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, rgba(31, 25, 49, 1) 20%, #1F1931 100%)' }} >
        <Header/>
        <div className='px-[5vw] flex flex-col items-center mt-32 text-[20px] mb-32 min-h-screen h-full  '>
          <div className='max-w-[1200px] w-full'>
        <h1 className='font-semibold text-[30px] mb-8'>Soundscape Privacy Policy</h1>
        <p className=''>At Soundscape, we value your privacy and are committed to protecting the personal information of our website visitors. This Privacy Policy outlines how we handle information collected from users who visit our website. Please take a moment to read this policy to understand how your data is handled</p>
        <h2 className='font-semibold text-[30px] mt-10'>Information We Collect</h2>
        <p className='mt-5'>We do not require or collect any personal information such as names, email addresses, or payment details from visitors to our website. You can browse the website content without logging in, signing up, or providing any personal data.</p>
        <p className=''>The only information we collect is basic, non-personal data that helps us understand how our visitors use the website. This may include:</p>
        <ol className='list-decimal pl-10 text-white '>
  <li>Browser Type and Version</li>
  <li>Operating System</li>
  <li>IP address (collected anonymously)</li>
</ol>

        <p className=''>This data is collected through cookies and analytics tools to help us improve the user experience and ensure the smooth functioning of the website.</p>
        <h2 className='font-semibold text-[30px] mt-10'>Security</h2>
       <p className='mt-5'>While we do not collect personal data, we implement reasonable security measures to protect the basic information we collect from unauthorized access. However, please note that no online service is completely secure.</p>
       <h2 className='font-semibold text-[30px] mt-10'>Changed To This Policy</h2>
        <p className='mt-5'>We may update this Privacy Policy occasionally to reflect changes in our practices or for legal and regulatory reasons. We encourage you to review this page periodically to stay informed of any updates.</p>
        <h2 className='font-semibold text-[30px] mt-10'>Contact Us</h2>
        <p className='mt-5'>If you have any questions, concerns, or suggestions regarding this Privacy Policy or our privacy practices, please contact us at sashidhar.dev@icloud.com.</p>
        <p className=''>By using the Soundscape website, you agree to this Privacy Policy</p>
        </div>


    </div>
    <NewFooter/>
    </div>
    </div>
  )
}

export default PrivacyPolicy