import React, { useRef, useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import styled from 'styled-components';
import LeftArrow from "../../assets/images/leftarrow.svg";
import RightArrow from "../../assets/images/rightarrow.svg";
import BlogCard from './BlogCard';
import { useNavigate } from 'react-router-dom';


const SliderContainer = styled.div`
    position: relative;
    width: 100%; 
    margin: 0 auto; /* Center horizontally */
    height: auto;
    overflow: visible;
  `;

  const ArrowButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;

    img {
      width: 60px; 
      height: 60px; 
    }

    
    &:hover{
      opacity: 0.8;
    }
              &:disabled {
        opacity:0.4
        }
  `;
const SliderComponent = ({category,reviews,id,title}) => {
const navigate=useNavigate();

  const carouselRef = useRef();
  const [responsive, setResponsive] = useState(getResponsive());
  const [currentIndex, setCurrentIndex] = useState(0);
  function getResponsive() {
    const width = window.innerWidth;
        if (width >= 1100) {
      return { reviews: 3 };
    }  else if (width >= 1000) {
      return { reviews: 2.5 };
    } 
    else if(width>=700) {
      return { reviews: 2 };
    }
    else  {
      return { reviews: 1 };
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setResponsive(getResponsive());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleBeforeChange = (nextIndex) => {
    setCurrentIndex(nextIndex);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit', 
    second: '2-digit',
  };
  return date.toLocaleDateString(undefined, options);
}


  // const handleNext = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.next();
  //   }
  // };

  // const handlePrevious = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.previous();
  //   }
  // };

  return (
    <section className='text-white relative bg-[#1F1931] mb-10'>
         <div className='flex justify-between'>   
      <h2 className='text-[28px] font-semibold '>{title||category}</h2>
      <div className='flex gap-4'>
            <button

                    onClick={() => carouselRef.current.goToSlide(currentIndex - 1)}
                    disabled={currentIndex <= 0}
                    style={{cursor: currentIndex <= 0 ? "not-allowed " :"" }} 
                    className={`w-10 h-10 ${currentIndex <= 0 ? "opacity-50" : "opacity-100"}`}
                >
                    <img src={LeftArrow} alt="Left Arrow" />
                </button>
                <button

onClick={() => carouselRef.current.goToSlide(currentIndex + 1)}
disabled={currentIndex >= reviews.length - responsive.reviews}
style={{ cursor: currentIndex >= reviews.length - responsive.reviews ? "not-allowed" : "" }} 
className={`w-10 h-10 ${currentIndex >= reviews.length - responsive.reviews ? "opacity-50" : "opacity-100"}`}
>
<img src={RightArrow} alt="right Arrow" />
</button>
</div>
      </div>
      <div className=''>

        <SliderContainer>
          

          <Carousel
            responsive={{
              superLargeDesktop: {
                breakpoint: { max: 4000, min: 2000 },
                items: responsive.reviews
              },
              desktop: {
                breakpoint: { max: 2000, min: 1024 },
                items: responsive.reviews
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: responsive.reviews
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: responsive.reviews
              }
            }}
            beforeChange={(nextIndex) => handleBeforeChange(nextIndex)}
            ref={carouselRef}
            arrows={false}
            containerClass="carousel-container p-0 m-0"
            swipeable={true}
            draggable={true}
             className='flex justify-between gap-0 sm:p-0 ml-0 mr-0 pt-10 mt-8'
          >
            {reviews.map((item, index) => (
              <div
                key={index}
                className=''
                style={{
                  position: 'relative',
                  display: 'flex',
                  
                  
                  
                }}
              >
                {reviews.length<=3&&index<reviews.length&&  <BlogCard image={item?.yoast_head_json.og_image[0]?.url} date={formatDate(item?.date)} title={item?.title?.rendered} category={category} path={id} slug={item?.slug}/>}
               {reviews.length>3&&index<reviews.length-1&&  <BlogCard image={item?.yoast_head_json.og_image[0]?.url} date={formatDate(item?.date)} title={item?.title?.rendered} category={category} path={id} slug={item?.slug}/>}
               {reviews.length>3&&index===reviews.length-1 &&<div className='h-40 flex justify-center items-center'> <button
  className='px-6 py-2 cursor-pointer text-[14px] hover:bg-[#EEEEEE] font-bold rounded-full bg-white shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] text-black w-fit'
  onClick={()=>{
    window.scrollTo(0, 0);
    navigate(id);
  }}
>
  Read more
</button> </div>}

              </div>
            ))}
          </Carousel>


        </SliderContainer>

      </div>
      
    </section>
  );
};

export default SliderComponent;
