// HeadingSection.js
import React, { useState, useEffect } from 'react';
import Angry from "../../../assets/images/Angry.png";
import Distracted from "../../../assets/images/Distracted.png";
import Tired from "../../../assets/images/Frame 33623.png";
import { useNavigate } from 'react-router-dom';

const images = [
    { src: Tired, label: "Tired" },
    { src: Angry, label: "Angry" },
    { src: Distracted, label: "Distracted" },
];

const HeadingSection = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleExploreClick = () => {
    const element = document.getElementById('Tired'); // Replace 'your-element-id' with the actual id
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    console.log()
  };

  return (
    <div className="flex flex-col px-[5vw] items-center">
      <div className="max-w-[1200px] w-full">
        <div className="flex sm:flex-row flex-col md:mt-36 mt-28 cursor-default ">
          
          {/* Custom Image Slider */}
          <div className="w-full justify-start items-center sm:hidden relative flex mt-40 mb-44 ">
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={image.label}
                className={`absolute transition-opacity duration-1000 ease-in-out w-fit h-fit ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
              />
            ))}
          </div>

          {/* Text Content */}
          <div className="sm:w-1/2 w-full text-white flex flex-col md:gap-[30px] gap-7 mt-14 ">
            <h1 className="md:text-[35px] text-[30px]">Music to Match Your 
              <span className="bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold"> Mood</span>: Good
              <span className="bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold"> Sounds </span>for Every
              <span className="bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold"> Emotion </span> 
            </h1>

            <p className="text-[#B4BCD0]  text-[16px]">
            Whatever you're feeling, our Moods section has a playlist to match. From uplifting tunes to calming sounds, each playlist is designed to fit right in with how you feel or want to feel. Pick a mood and let the music take you where you want to go.

            </p>

            <button
              className="px-6 py-2 cursor-pointer text-[14px] mt-[10px] hover:bg-[#EEEEEE] font-bold rounded-full bg-white shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] text-black w-fit"
              onClick={handleExploreClick}
            >
              Play Now
            </button>
          </div>

          {/* Image Slider for Larger Screens */}
          <div className="w-1/2 sm:flex hidden relative justify-center items-center mt-10">
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={image.label}
                className={`absolute transition-opacity duration-1000 ease-in-out w-fit h-fit ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadingSection;
