import React from 'react';
import Line from "../../../assets/images/Group 7422.svg";
import brainwaves from "../../../assets/images/brainwaves (1) 1.webp";
import guided from "../../../assets/images/lotus (1) 1.webp";
import radio from "../../../assets/images/radio (2) 1.webp";
import moon from "../../../assets/images/moon 1.webp";

const FeatureSection = () => {
  return (
    <div className='flex-col items-center bg-[#1F1931] pt-32 flex'>
      <div className='w-full max-w-[1200px] text-[#B4BCD0] text-xl px-[5vw] flex flex-col gap-10'>
        <h2 className='text-[35px] text-white mx-10 leading-tight text-center'>
          We Can’t <span className='bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Silence</span> The <span className='bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>World</span> But We Can Help You <span className='bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Create</span> Your Own <span className='bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Oasis</span>
        </h2>
        <p className='text-center mx-10 text-[16px]'>
          We’re here to help you feel better. Find instant relief from stress and anxiety with guided meditations, soothing brainwaves, and calming sleep stories. Dive into a peaceful sleep and wake up refreshed and focused.
        </p>
        <div className='flex xs:flex-row flex-col justify-between items-start'>
          <div className='flex gap-[30px] items-start'>
            <img src={Line} alt="partition line" className='h-96' />
            <div className='flex flex-col gap-20 my-20'>
              <div>
                <div className='flex gap-4'>
                  <h3 className='text-white text-[26px]'>Brainwaves</h3>
                  <img src={brainwaves} alt="brainwaves logo" className='w-10 h-10 -mt-2' />
                </div>
                <p className='text-start text-[16px]  mt-1'>
                Enhance focus and relaxation with calming brainwave tracks
                </p>
              </div>
              <div>
                <div className='flex gap-4'>
                  <h3 className='text-white text-[26px]'>Guided Meditation</h3>
                  <img src={guided} alt="guided logo" className='w-8 h-8 -mt-2' />
                </div>
                <p className='text-start text-[16px] mt-2'>
                  Achieve mindfulness through expertly Guided Meditation sessions.
                </p>
              </div>
            </div>
          </div>
          <div className='flex gap-[30px] items-start'>
            <img src={Line} alt="partition line" className='h-96' />
            <div className='flex flex-col gap-20 my-20'>
              <div>
                <div className='flex gap-4'>
                  <h3 className='text-white text-[26px]'>Live Radio</h3>
                  <img src={radio} alt="radio logo" className='w-8 h-8 -mt-2' />
                </div>
                <p className='text-start text-[16px]  mt-2'>
                  Tune into soothing live radio channels for uninterrupted peace.
                </p>
              </div>
              <div>
                <div className='flex gap-4'>
                  <h3 className='text-white text-[26px]'>Bedtime Stories</h3>
                  <img src={moon} alt="moon logo" className='w-8 h-8 -mt-2' />
                </div>
                <p className='text-start text-[16px] mt-2'>
                  Drift into restful sleep with calming bedtime stories.
                </p>
              </div>
            </div>
          </div>
          <img src={Line} alt="partition line" className='xs:flex hidden h-96' />
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
