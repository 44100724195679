import React from "react";
import MobilePhone from "../../../assets/images/classic phone.svg";
import MobilePhone2 from "../../../assets/images/phonebrainwaves.svg";
import oldsong from "../../../assets/images/oldsong.svg";
import live from "../../../assets/images/80live.svg";
import classic from "../../../assets/images/clasicrock.svg";
import brainhealth from "../../../assets/images/1st.svg";
import pancreas from "../../../assets/images/2nd.svg";
import muscle from "../../../assets/images/muscletwo.svg";
import { useNavigate } from "react-router-dom";

const SongSection = () => {
  const navigate = useNavigate();
  
  const handleExploreClick = () => {
    window.scrollTo(0, 0);
    navigate("/radio");
  };

  const handleExploreClick2 = () => {
    window.scrollTo(0, 0);
    navigate("/brainwaves");
  };

  return (
    <div className='flex flex-col items-center px-[5vw] pt-32 bg-[#1F1931]'>
      <div className='flex sm:flex-row flex-col w-full max-w-[1200px] gap-10 justify-center items-stretch'>
        
        {/* Online Radio Card */}
        <div className='flex-1 min-h-[500px] border border-[#413563] rounded-[45px] bg-gradient-to-r from-[#161224] via-[#312B43] to-[#171325] flex flex-col justify-between pl-6 pt-6'>
          <div className='flex flex-col h-full'>
            <div className=' flex-grow'>
              <h2 className='text-white text-[20px]'>Online Radio</h2>
              <p className='mt-2 text-[#B4BAD0] text-[14px] pr-[44px]'>
              Enjoy your favourite stations anytime, anywhere with our radio collection. Offering endless soothing tunes and calming melodies, our soundscapes online FM radio brings you relaxing sounds to help you rest no matter where you are.
              </p>
            </div>
            <button className='px-6 py-2 cursor-pointer text-[14px] shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] hover:bg-[#EEEEEE] font-bold rounded-full bg-white text-black w-auto mt-3 self-start' onClick={handleExploreClick}>
              Explore Now!
            </button>
          </div>
          <div className='flex justify-end items-end gap-5 mt-auto xs:pr-0 '>
            <div className='flex gap-4 justify-end items-center'>
              <div className='flex flex-col gap-3 pb-6 pt-2'>
                <img src={classic} alt="classic" className='w-32 h-[112px] object-contain' />
                
              </div>
              <div className='flex flex-col gap-5 pb-6 pt-2'>
              <img src={live} alt="live" className='w-32 h-[112px] object-contain' />
                <img src={oldsong} alt="oldsong" className='w-32 h-[112px] object-contain' />
              </div>
            </div>
            <div className='w-1/3 xs:flex hidden'>
              <img src={MobilePhone} alt="phone" className="sm:w-full xs:w-auto h-auto object-cover rounded-br-3xl" />
            </div>
          </div>
        </div>

        {/* Brainwaves Card */}
        <div className='flex-1 min-h-[500px] border border-[#413563] rounded-[45px] bg-gradient-to-r from-[#161224] via-[#312B43] to-[#171325] flex flex-col justify-between pl-6 pt-6'>
          <div className='flex flex-col h-full'>
            <div className=' flex-grow'>
              <h2 className='text-white  text-[20px] '>Brainwaves</h2>
              <p className='mt-3 text-[#B4BAD0] text-[14px] pr-[44px]'>
                Discover your ideal frequency with our brainwave tracks, carefully tailored to meet your individual needs. Experience the transformative power of brainwaves in your daily life, enhancing your focus, reducing stress, and fostering a deeper sense of well-being.
              </p>
            </div>
            <button className='px-6 py-2 text-[14px] cursor-pointer  shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] hover:bg-[#EEEEEE] font-bold rounded-full bg-white text-black w-auto mt-5 self-start' onClick={handleExploreClick2}>
              Explore Now!
            </button>
          </div>
          <div className='flex justify-end items-end gap-5 mt-auto xs:pr-0 '>
            <div className="flex justify-end items-center gap-1">
            <div className='flex flex-col gap-3 pb-4 pt-2'>
              <img src={muscle} alt="muscle" className='w-[130px] h-[112px] object-contain ' />
              </div>
              <div className='flex flex-col gap-5 pb-6 pt-2'>
                <img src={brainhealth} alt="brainhealth" className='w-32 h-[112px] object-contain' />
                <img src={pancreas} alt="pancreas" className='w-32 h-[112px] object-contain' />
              </div>
            </div>
            <div className='w-1/3 xs:flex hidden'>
              <img src={MobilePhone2} alt="phone" className='sm:w-full xs:w-auto h-auto  object-cover rounded-br-3xl' />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default SongSection;
