import React, { useEffect, useState ,useRef} from 'react';
import brainhealth from "../../assets/images/1st.svg";
import pancreas from "../../assets/images/2nd.svg";
import muscle from "../../assets/images/muscletwo.svg";
import first from "../../assets/images/111 Hz.webp"
import second from "../../assets/images/174 Hz.webp"
import fifth from "../../assets/images/396 Hz.webp"
import sixth from "../../assets/images/417 Hz.webp"
import seventh from "../../assets/images/117 Hz.webp"
import eight from "../../assets/images/144 Hz.webp"
import nine from "../../assets/images/220 Hz.webp"
import ten from "../../assets/images/285 Hz.webp"
import Pink from "../../assets/images/Pink.webp"
import Purple from "../../assets/images/Purple.webp"
import Orange from "../../assets/images/Orange.webp"
import blue from "../../assets/images/blue.webp"
import Header from '../Header';
import bg2 from "../../assets/images/Group 7309.webp"
import BrainwaveCard from './BrainwaveCard';
import { useNavigate } from 'react-router-dom';
import FAQSection from './FAQSection';

const BrainWaves = () => {
  const images = [
    { src: brainhealth, label: "brainhealth song1" },
    { src: pancreas, label: "brainhealth song2" },
    { src: muscle, label: "brainhealth song3" },
];
    const [isButton1, setButton1] = useState(true);
    const [isButton2, setButton2] = useState(false);
    const [firstOption,setFirstOption]=useState(null);
    const [secondOption,setSecondOption]=useState(null)
    const [currentAudio, setCurrentAudio] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [songId,setSongId]=useState(null);
    const audioRef = useRef(null);
    const navigate=useNavigate();
    
    const customStyle = {
        backdropFilter: 'blur(7px)',
    };

    
    
   const mentalWellFrequency=[
    second,ten,fifth,sixth
   ]

   const physicalWellFrequency=[
    first,seventh,eight,nine
   ]

   const deltaWaves=["0.5 Hz","1.5 Hz","3.5 Hz","4 hz"];
   const thetaWaves=["5 Hz","5.5 Hz","6 Hz"," 7 Hz"];
   const alphawaves=["8.3 Hz"," 8.5 Hz","9 Hz","10 Hz"];

    const handleSoundscapeClick = () => {
        window.open('https://play.google.com/store/apps/details?id=com.music.focusflow&pcampaignid=web_share', '_blank');
    };
    const [currentIndex, setCurrentIndex] = useState(0);

   

    const [loading, setLoading] = useState(false);

  const handlePlayPause = (audioUrl, id) => {
    if (currentAudio === audioUrl) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
        setSongId(null);
      } else {
        audioRef.current.play();
        setIsPlaying(true);
        setSongId(id);
      }
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setLoading(true); // Show loader while new audio is being prepared
      const newAudio = new Audio(audioUrl);
      setSongId(id);
      // Set up event listener for when the audio is ready to play
      newAudio.oncanplaythrough = () => {
        setLoading(false); // Hide loader when audio is ready
        newAudio.play();
        setIsPlaying(true);
       
        setCurrentAudio(audioUrl);
      };

      newAudio.onerror = () => {
        setLoading(false); // Hide loader in case of error
        console.error("Audio failed to load.");
      };

      audioRef.current = newAudio;
    }
  };

     
    const fetchData = async () => {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/web_brainwaves/?id=6");
        const result = await response.json();
        console.log(result);
        setFirstOption(result);
    };
     
    const fetchData2 = async () => {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/web_brainwaves/?id=8");
        const result = await response.json();
        console.log(result);
        setSecondOption(result);
    };


    useEffect(() => {
        fetchData();
        fetchData2();
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null; 
                setIsPlaying(false);
                setCurrentAudio(null);
                setSongId(null);
            }
        };
    },[]);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);
      return () => clearInterval(interval);
    }, []);

    const handleExploreClick = () => {
        window.scrollTo(0, 0);
        navigate("/brainwaves");
      };

      
    return (
        <div className="min-h-screen bg-gradient-to-b" style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 1%, rgba(31, 25, 49, 1) 20%, #1F1931 100%)' }}>

                <Header  />
                <div className='flex flex-col items-center px-[5vw]'>
                <div className='max-w-[1200px] w-full  '>
                <div className='flex sm:flex-row flex-col justify-between md:mt-36 mt-28 cursor-default'>
                    
                <div className="w-full justify-start items-center sm:hidden relative flex mt-40 mb-44 ">
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={image.label}
                className={`absolute transition-opacity duration-1000 ease-in-out w-full h-72 ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
              />
            ))}
          </div>
          <div className='sm:w-1/2 w-full text-white flex flex-col md:gap-[30px] gap-7 mt-14'>
            <h1 className='md:text-[35px] text-[30px] '>
            Boost 
              <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'> Focus </span>and  <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Relax </span>with <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Brainwave</span> Sounds 
            
            </h1>

            <p className='text-[#B4BCD0] text-[16px]'>
            Unlock a new level of focus and relaxation with our Brainwaves category. Featuring special sound frequencies, these tracks can help you concentrate, feel calm, or simply unwind. Perfect for studying, working, or taking a break, brainwave sounds help you tune into the right mood.
            </p>

           
            <button
  className='px-6 py-2 cursor-pointer text-[14px] mt-[10px] hover:bg-[#EEEEEE] font-bold rounded-full bg-white shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] text-black w-fit'
  onClick={() => {
    const element = document.getElementById(isButton1?'Mental Well being':"Delta Waves"); // Replace 'your-element-id' with the actual id
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }}
>
  Play Now
</button>
          </div>
          <div className="w-1/2 sm:flex hidden relative justify-center items-center mt-5">
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={image.label}
                className={`absolute transition-opacity duration-1000 ease-in-out w-full h-80 ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
              />
            ))}
          </div>

        </div>
        <h2 className='text-center text-[35px] text-white  pt-[90px]'id={isButton1?firstOption?.result[0].category_name:secondOption?.result[0].category_name}>Brainwaves For Inner <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Balance</span></h2>
        <div className='flex  text-white gap-10 z-30 justify-center mt-[14px]'>

                <button
                    className={`${isButton1 ? "bg-gradient-to-br from-white/60 to-[#474055]" : "border border-[#FFF4F4]"} py-3 px-7 rounded-full mt-10 cursor-pointer z-30 `}
                    onClick={() => {
                        setButton1(true);
                        setButton2(false);
                    }}
                    style={customStyle}
                >
                    Solfeggio Frequencies
                </button>
                <button
                    className={`${isButton2 ? " bg-gradient-to-br  from-white/60 to-[#474055]" : "border border-[#FFF4F4]"}  py-3 px-10 rounded-full mt-10 cursor-pointer z-30`}
                    onClick={() => {
                        setButton2(true);
                        setButton1(false);
                    }}
                    style={customStyle}
                >
                    Binaural Beats
                </button>
            </div>

            


                {(isButton1 &&firstOption) &&(
                <div className=' flex flex-col' >
                    <h3 className='font-semibold text-left text-white  text-xl my-[50px] ' >{firstOption?.result[0].category_name}</h3>
                    
                    
                    <div className='flex justify-between gap-[30px] z-30 flex-wrap '>
                        {firstOption?.result[0]?.audios.map((i, index) => (
                            // <BrainwaveCard image={i.process.env.REACT_APP_MEDIA_URL+image_path}  alt={i.audio_name}/>
                            <diV>
                                                             <BrainwaveCard image={process.env.REACT_APP_MEDIA_URL+i.image_path} BackgroundCardImage={index===0?Pink:index===1?blue:index===2?Purple:Orange} songName={i.audio_name} audio={process.env.REACT_APP_MEDIA_URL+i.audio_path} id={i.id} checkButton={isButton1} frequency={mentalWellFrequency[index]} onPlayPause={handlePlayPause} loading={loading} playSongId={songId}/>
                            </diV>
                        ))}
                    </div>
                   
                
                    <h3 className='font-semibold text-left text-white mt-[90px] mb-[50px] text-xl'>{firstOption?.result[1].category_name}</h3>

                    
                    <div className='flex justify-between gap-[30px] flex-wrap '>
                        {firstOption?.result[1]?.audios.map((i, index) => (

                            <div>                             <BrainwaveCard image={process.env.REACT_APP_MEDIA_URL+i.image_path} BackgroundCardImage={index===0?blue:index===1?Purple:index===2?Pink:Orange} songName={i.audio_name} audio={process.env.REACT_APP_MEDIA_URL+i.audio_path} id={i.id} loading={loading}  checkButton={isButton1} frequency={physicalWellFrequency[index]} onPlayPause={handlePlayPause} playSongId={songId}/></div>
                        ))}

                    </div>
                    <div className='flex justify-center items-center mt-[60px] '>
                    <button
  className='px-6 py-2 cursor-pointer text-[14px] hover:bg-[#EEEEEE] font-bold rounded-full bg-white shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] text-black w-fit'
  onClick={() => {
    handleSoundscapeClick();
  }}
>
  Explore Soundscape
</button>
                    </div>
                   
                
                </div>
            )}
                        {(isButton2 && secondOption)&& (
                <div className='text-white ' >
                    <h3 className='font-semibold text-left  text-xl my-[50px]'>{secondOption.result[0].category_name}</h3>
                   
                    <div className='flex justify-between gap-[30px] flex-wrap ' >
                        {secondOption.result[0].audios.map((i, index) => (<div>
                            <BrainwaveCard image={`${process.env.REACT_APP_MEDIA_URL}/media/images/bianural_beats/image_145.png`} BackgroundCardImage={index===0?Pink:index===1?blue:index===2?Purple:Orange} songName={i.audio_name} loading={loading} audio={process.env.REACT_APP_MEDIA_URL+i.audio_path} id={i.id} checkButton={isButton1} frequency={deltaWaves[index]} onPlayPause={handlePlayPause} playSongId={songId} color1={"#"+i.color_codes[0]} color2={"#"+i.color_codes[1]}/>
                        </div>
                        ))}
                    </div>
                  
                    
                    <h3 className='font-semibold text-left  text-xl mt-[90px] mb-[50px]'>{secondOption.result[1].category_name}</h3>

                
                    <div className='flex justify-between gap-[30px] flex-wrap '>
                    {secondOption.result[1].audios.map((i, index) => (<div>
                        <BrainwaveCard image={`${process.env.REACT_APP_MEDIA_URL}/media/images/bianural_beats/image_145.png`} BackgroundCardImage={index===0?Pink:index===1?blue:index===2?Purple:Orange} loading={loading} songName={i.audio_name} audio={process.env.REACT_APP_MEDIA_URL+i.audio_path} id={i.id} checkButton={isButton1} frequency={thetaWaves[index]} onPlayPause={handlePlayPause} playSongId={songId} color1={"#"+i.color_codes[0]} color2={"#"+i.color_codes[1]}/>
                        </div>
                    ))}
                        
                    </div>

                    
                    
                    <h3 className='font-semibold text-left  text-xl mt-[90px] mb-[50px]'>{secondOption.result[2].category_name}</h3>

                    <div className='flex justify-between gap-[30px] flex-wrap '>
                    {secondOption.result[2].audios.map((i, index) => (<div>
                        <BrainwaveCard image={`${process.env.REACT_APP_MEDIA_URL}/media/images/bianural_beats/image_145.png`} BackgroundCardImage={index===0?Pink:index===1?blue:index===2?Purple:Orange} songName={i.audio_name} audio={process.env.REACT_APP_MEDIA_URL+i.audio_path} id={i.id} loading={loading} checkButton={isButton1} frequency={alphawaves[index]} onPlayPause={handlePlayPause} playSongId={songId} color1={"#"+i.color_codes[0]} color2={"#"+i.color_codes[1]}/>
                        </div>
                        ))}
                        
                    </div>
                    <div className='flex justify-center items-center mt-[60px] '>
                    <button
  className='px-6 py-2 cursor-pointer text-[14px] hover:bg-[#EEEEEE] font-bold rounded-full bg-white shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] text-black w-fit'
  onClick={() => {
    handleSoundscapeClick();
  }}
>
  Explore Soundscape
</button>
                    </div>
                    



                   
                </div>

               
            )}


           
                    </div>
                  
                
                    </div>
                    <FAQSection/>
       

            </div>


    );
};

export default BrainWaves;
