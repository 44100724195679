import React from 'react';
import PlayIcon from '../../assets/images/PlayIcon.svg';
import PauseIcon from "../../assets/images/Group 7348 (2).svg";
import { Audio } from 'react-loader-spinner';
import { ColorRing } from 'react-loader-spinner';

const RadioCard = ({ id, image, songName, audio, onPlayPause, playSongId,loading }) => {
  return (
    <div className='relative z-30'>
      <div
        className='relative rounded-lg w-72 group cursor-pointer'
        onClick={() => { onPlayPause(audio, id); }}
      >
        {/* Background Image */}
        <img
          src={image}
          className={`rounded-b-2xl transition-opacity duration-300 group-hover:opacity-50 ${playSongId === id ? "opacity-50" : ""}`}
          alt={songName}
        />
        
        {/* Play/Pause Icon */}
      <div className={`absolute inset-0 flex items-center justify-center z-20 ${playSongId === id? "opacity-100" : "opacity-0 group-hover:opacity-100"} transition-opacity duration-300`}>
         {!loading &&<img
            src={playSongId === id? PauseIcon :PlayIcon}
            alt={playSongId === id ? 'Pause Icon' :'Play Icon'}
            className='w-20 h-20'
          />
         }

        </div>

        {loading &&playSongId===id&& <div className='absolute inset-0 flex items-center justify-center w-full z-10'>
          <ColorRing visible={true}
                                        height="80"
                                        width="80"
                                        ariaLabel="color-ring-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="color-ring-wrapper"
                                        colors={['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']}
                                        />
          </div>}

        {/* Audio Spinner */}
      {!loading && <div className='absolute inset-0 flex items-end justify-center w-full z-10'>
          <Audio
            height="50" // Adjust height as needed
            width="100" 
            color="#FFFFFF"
            ariaLabel="audio-loading"
            wrapperStyle={{ }}
            wrapperClass="wrapper-class "
            visible={playSongId === id?true:false} 
          />
        </div>
      }
      </div>

      {/* Song Name */}
      <h3 className='text-center mt-2 text-[#B4BCD0]'>{songName}</h3>
    </div>
  );
};

export default RadioCard;
