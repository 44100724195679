import React from 'react'
import visual from "../../../assets/images/Group 7220.svg"
import inspiration from "../../../assets/images/Group 7285.svg"
import historical from "../../../assets/images/Group 7286.svg"
import { useNavigate } from 'react-router-dom'
const HeadingSection = () => {
    const navigate =useNavigate();
    const handleExploreClick = () => {
      const element = document.getElementById('The Myths and Legends'); // Replace 'your-element-id' with the actual id
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      };
  return (
    <div className='flex flex-col  px-[5vw] items-center'>
      <div className='max-w-[1200px] w-full'>
        <div className='flex sm:flex-row flex-col justify-center items-center md:mt-28 mt-20 cursor-default'>
        <div className='sm:hidden w-full flex justify-center items-center gap-5'>
            <div className='w-1/2'>
        <img src={historical} className='h-auto w-full' alt="historical"/>
        </div>
            <div className='flex flex-col gap-5  w-1/2'>
                <img src={inspiration} className='w-full h-auto ' alt="inspiration stories"/>
                <img src={visual} className='w-full h-auto' alt="visual stories"/>
            </div>
            
          </div>
          <div className='sm:w-1/2 w-full text-white flex flex-col md:gap-[30px] gap-7 mt-10'>
            <h1 className=' md:text-[35px] text-[30px] '>
            Ease with <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Stories  </span>That Calm the <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Mind </span>and Soothe the <span className='bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Soul</span>
            
            </h1>

            <p className='text-[#B4BCD0] text-[16px]'>
            Relax with our Stories, where calming bedtime tales help you let go of the day and drift off to sleep. Each story is told with a gentle voice and peaceful sounds to help you relax and fall asleep naturally. Just close your eyes, listen, and let these soothing stories carry you into a restful night.

            </p>

           
            <button
  className='px-6 py-2 cursor-pointer text-[14px] mt-[10px] hover:bg-[#EEEEEE] font-bold rounded-full bg-white shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] text-black w-fit'
  onClick={() => {
    handleExploreClick();
  }}
>
  Play Now
</button>
          </div>
          <div className='w-1/2  sm:flex hidden justify-center items-center gap-5 mt-5'>
          <img src={historical} className='h-48 w-48' alt="historical"/>
            <div className='flex flex-col gap-5'>
            <img src={inspiration} className='w-48 h-48 ' alt="inspiration stories"/>
            <img src={visual} className='w-48 h-48' alt="visual stories"/>
            </div>
            
          </div>
        </div>

      </div>
    </div>
  )
}

export default HeadingSection