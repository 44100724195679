import React from "react";
import blogimage from "../../assets/images/blogimage1.svg";
import SliderComponent from "./SliderComponent";
import Header from "./Header";

const NewBlogPage = ({
  mentalHealth,
  sleep,
  health,
  productivity,
  mindfullness,
  combinedData,
}) => {
  const MentalHealthCategory = mentalHealth;
  const SleepCategory = sleep;
  const proCategory = productivity;
  const wellbeing = health;
  const mindfullnessCategory = mindfullness;

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  };

  // Find the most recent item in combinedData
  const getMostRecentItem = (data) => {
    if (!data || data.length === 0) return null;

    return data.reduce((latest, current) => {
      const latestDate = new Date(latest.date);
      const currentDate = new Date(current.date);

      return currentDate > latestDate ? current : latest;
    });
  };

  const recentItem = getMostRecentItem(combinedData);

  return (
    <div
      className="min-h-screen h-full pb-28 flex flex-col gap-10 bg-gradient-to-b"
      style={{
        backgroundImage:
          "linear-gradient(to bottom, rgba(0, 0, 0, 1) 5%, rgba(31, 25, 49, 1) 10%, #1F1931 100%)",
      }}
    >
      <Header />
      <div className="px-[5vw]">
        <div className="flex flex-col items-center mb-10 ">
          <div className="w-full max-w-[1200px] mt-5">
            <div className="flex flex-col gap-4 rounded-3xl bg-[#362F45] mt-28 mb-10">
              <img
                src={recentItem?.yoast_head_json.og_image[0]?.url}
                alt={recentItem?.title?.rendered}
                className="w-full max-h-[500px] h-screen object-cover rounded-t-3xl"
              />
              <div className="flex flex-col gap-2 px-4 py-5">
                <p className="text-[#FFB457] text-[15px] font-semibold">
                  {recentItem?.category}
                </p>
                <h1 className="bg-gradient-to-r from-white to-[#999999] text-transparent bg-clip-text font-medium text-[30px]">
                  {recentItem?.title?.rendered || "How Brainwaves Are Effective?"}
                </h1>
                <div className="flex gap-6 text-[#B4BCD0] text-[12px] pb-1 font-semibold">
                  <p>{formatDate(recentItem?.date)}</p>

                </div>
              </div>
            </div>
            <SliderComponent
          category={"Mental Health"}
          reviews={MentalHealthCategory}
          id={"/blog/mental-health"}
        />
        <SliderComponent
          category={"Sleep"}
          reviews={SleepCategory}
          id={"/blog/sleep"}
        />
        <SliderComponent
          category={"Productivity"}
          reviews={proCategory}
          id={"/blog/productivity"}
        />
        <SliderComponent
          category={"Well Being"}
          reviews={wellbeing}
          id={"/blog/well-being"}
        />
        <SliderComponent
          category={"Mindfullness"}
          reviews={mindfullnessCategory}
          id={"/blog/mindfullness"}
        />
          </div>
          
        </div>
       
      </div>
    </div>
  );
};

export default NewBlogPage;
