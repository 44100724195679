import React ,{useState ,useEffect} from 'react';
import Player from '../../Player';


const StoriesSection= () => {
    const [playingTrack, setPlayingTrack] = useState(null); 
    const [isPlaying, setIsPlaying] = useState(false);
   
    const width = "sm:w-full w-[300px]  sm:h-64";
    const [episodePlaylist,setEpisodePlaylist]=useState(null);
    const storyplaylist = [
        { story: process.env.REACT_APP_MEDIA_URL+episodePlaylist?.result[0].image_path ,storyName:"The Myths and Legends",stories:episodePlaylist?.result[0].episodes,duration:["05:54","","13:12","05:38","10:35"] },
        { story: process.env.REACT_APP_MEDIA_URL+episodePlaylist?.result[1].image_path,storyName:episodePlaylist?.result[1].story_name,stories:episodePlaylist?.result[1].episodes,duration:["04:38"]},
        { story: process.env.REACT_APP_MEDIA_URL+episodePlaylist?.result[2].image_path ,storyName:episodePlaylist?.result[2].story_name,stories:episodePlaylist?.result[2].episodes,duration:["03:34"]},
    ];
    const text="All Stories"
    const handleTrackClick = (songName) => {
        if (playingTrack === songName) {
            // If the track is already playing, pause it
            setIsPlaying(false); 
        } else {
            // If another track is clicked, start playing it
            setPlayingTrack(songName); 
            setIsPlaying(true); 
        }
    };

    useEffect(() => {
        if (!isPlaying) {
            setPlayingTrack(null);
        }
    }, [isPlaying]);

    const fetchData=async()=>{
        const data=await fetch(process.env.REACT_APP_BACKEND_URL+"/web_stories");
        const result=await data.json();
        console.log(result);
        setEpisodePlaylist(result);
    }


    useEffect(()=>{
       fetchData();
    },[episodePlaylist])

    return (
        

            

        <div className='pt-[80px]  items-center flex flex-col md:px-[5vw] sm:px-[3vw] px-[5vw] relative'>
        <div className='w-full max-w-[1200px] relative text-white'>
<h2 className='text-center text-[35px] 'id={storyplaylist[0]?.storyName}>Stories For Peaceful <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Sleep</span></h2>
                {episodePlaylist&&storyplaylist.map((i, index) => (
                    <Player 
                    key={index} 
                    image={i.story} 
                    width={width} 
                    name={i.storyName} 
                    duration={i.duration}
                    songs={i.stories}
                    onPlayTrack={handleTrackClick}
                    isPlaying={playingTrack === i.storyName && isPlaying} 
                    setIsPlaying={setIsPlaying}
                    />
                ))}
                </div>

                
            </div>
        
            
    );
};

export default StoriesSection;
