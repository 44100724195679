import React from 'react'
import gratefull from "../../../assets/images/Group 7223 (1).svg"
import mindfullness from "../../../assets/images/Group 7220 (1) 1.svg"
import selfLove from "../../../assets/images/Group 7221 (1).svg"
import { useNavigate } from 'react-router-dom'
const HeadingSection = () => {
    const navigate =useNavigate();
    const handleExploreClick = () => {
        const element = document.getElementById('Mindfulness'); // Replace 'your-element-id' with the actual id
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };
  return (
    <div className='flex flex-col  px-[5vw] items-center'>
      <div className='max-w-[1200px] w-full'>
        <div className='flex sm:flex-row flex-col justify-center items-center md:mt-28 mt-20 cursor-default'>
        <div className='sm:hidden w-full flex justify-center items-center gap-5'>
            <div className='flex flex-col gap-5  w-1/2'>
                <img src={mindfullness} className='w-full h-auto ' alt="mindfullness"/>
                <img src={gratefull} className='w-full h-auto' alt="gratefull"/>
            </div>
            <div className='w-1/2'>
            <img src={selfLove} className='h-auto w-full' alt="selfLove"/>
            </div>
          </div>
          <div className='sm:w-1/2 w-full text-white flex flex-col md:gap-[30px] gap-7 mt-10'>
            <h1 className='md:text-[35px] text-[30px] '>
            Take a Moment to Breathe: <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Guided Meditations </span>for <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Calm </span>and <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Clarity </span> 
            </h1>

            <p className='text-[#B4BCD0] text-[16px]'>
            Take a break with our Guided Meditation sessions, created to help you relax, de-stress, and find peace. Each session is led by calming sounds and gentle music, perfect for all. Settle in, breathe deep, and let these meditations guide you to a calmer state of mind.

            </p>

           
            <button
  className='px-6 py-2 cursor-pointer text-[14px] hover:bg-[#EEEEEE] font-bold rounded-full bg-white shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] text-black w-fit'
  onClick={() => {
    handleExploreClick();
  }}
>
  Play Now
</button>
          </div>
          <div className='w-1/2  sm:flex hidden justify-center items-center gap-5 mt-5'>
            <div className='flex flex-col gap-5'>
                <img src={mindfullness} className='w-48 h-48' alt="mindfullness"/>
                <img src={gratefull} className='w-48 h-48' alt="gratefull"/>
            </div>
            <img src={selfLove} className='w-48 h-48' alt="selfLove"/>
          </div>
        </div>

      </div>
    </div>
  )
}

export default HeadingSection