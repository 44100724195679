import React from 'react'
import meditation from "../../../assets/images/Group 7221.webp"
import motivation from "../../../assets/images/Group 7223.webp"
import fitness from "../../../assets/images/Group 7224 1.webp"
import { useNavigate } from 'react-router-dom'
const HeadingSection = () => {
    const navigate =useNavigate();
    const handleExploreClick = () => {
      const element = document.getElementById('Sleep'); // Replace 'your-element-id' with the actual id
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      };
  return (
    <div className='flex flex-col  px-[5vw] items-center'>
      <div className='max-w-[1200px] w-full'>
        <div className='flex sm:flex-row flex-col justify-center items-center  md:mt-28 mt-20 cursor-default'>
        <div className='sm:hidden w-full flex justify-center items-center gap-5'>
            <div className='flex flex-col gap-5  w-1/2'>
                <img src={motivation} className='w-full h-auto ' alt="motivation"/>
                <img src={meditation} className='w-full h-auto' alt="meditation"/>
            </div>
            <div className='w-1/2'>
            <img src={fitness} className='h-auto w-full' alt="fitness"/>
            </div>
          </div>
          <div className='sm:w-1/2 w-full text-white flex flex-col md:gap-[30px] gap-7 mt-10'>
            <h1 className='md:text-[35px]  text-[30px] '>
            Explore Our Most Loved <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Playlists </span>The <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Sounds </span>Everyone’s Talking About
            
            </h1>

            <p className='text-[#B4BCD0] text-[16px] '>
            Discover our Top Playlist, featuring the most-loved tracks on Soundscape. Whether you want to relax, focus, or get energised, these playlists have been handpicked by our team and loved by listeners like you. Press play and find your new favourite sound!
            </p>

           
            <button
  className='px-6 py-2 cursor-pointer md:text-[14px] md:mt-[10px] text-[14px] hover:bg-[#EEEEEE] font-bold rounded-full bg-white shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] text-black w-fit'
  onClick={() => {
    handleExploreClick();
  }}
>
  Play Now
</button>
          </div>
          <div className='w-1/2  sm:flex hidden justify-center items-center gap-5 mt-5'>
            <div className='flex flex-col gap-5'>
                <img src={motivation} className='w-48 h-48' alt="motivation"/>
                <img src={meditation} className='w-48 h-48' alt="meditation"/>
            </div>
            <img src={fitness} className='w-48 h-48' alt="fitness"/>
          </div>
        </div>

      </div>
    </div>
  )
}

export default HeadingSection