import React, { useRef, useEffect, useState } from 'react';
import ReviewCard from './ReviewCard';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import styled from 'styled-components';
import LeftArrow from "../../../assets/images/leftarrow.svg";
import RightArrow from "../../../assets/images/rightarrow.svg";

const SliderContainer = styled.div`
    position: relative;
    width: 90%; 
    margin: 0 auto; /* Center horizontally */
    height: auto;
    overflow: visible;
  `;

  const ArrowButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;

    img {
      width: 60px; 
      height: 60px; 
    }

    
    &:hover{
      opacity: 0.8;
    }
              &:disabled {
        opacity:0.4
        }
  `;
const ReviewSection = () => {

  const carouselRef = useRef();
  const [responsive, setResponsive] = useState(getResponsive());
  const [currentIndex, setCurrentIndex] = useState(0);
  function getResponsive() {
    const width = window.innerWidth;

    if (width >= 2500) {
      return { reviews: 6 };
    } else if (width >= 2200) {
      return { reviews: 5 };
    } else if (width >= 1800) {
      return { reviews: 4 };
    } else if (width >= 1300) {
      return { reviews: 3 };
    } else if (width >= 800) {
      return { reviews: 2 };
    } else {
      return { reviews: 1 };
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setResponsive(getResponsive());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleBeforeChange = (nextIndex) => {
    setCurrentIndex(nextIndex);
};

  const reviews = [
    {
      comment: "The interface is intuitive, making exploration effortless, and the song catalog ensures there's something for every occasion, and the audio quality is exceptional, enhancing the enjoyment of every track.",
      name: "Aman"
    },
    {
      comment: "I use this app during my meditation practice, and it enhances the whole experience. The sounds are calming and help me get into the right mindset. Love the variety it offers!",
      name: "Zunnoor"
    },
    {
      comment: "Good graphics, App running smooth Help me with the my meditation",
      name: "Shalini"
    },
    {
      comment: "Soundscape has been a game-changer. The ambient sounds instantly calm me down, and I can customize them to my mood. Highly recommend!",
      name: "Bhavya"
    },
    {
      comment: "I use Soundscape both for work and meditation. The calming sounds really help me stay focused and stress-free.",
      name: "Kiran"
    },
    {
      comment: "Impressed with the experience and smoothness of the app.",
      name: "Mokshita"
    },
    {
      comment: "Soundscape helps me escape from my stressful day. I use the app to create a peaceful atmosphere at home, and it helps me unwind before bed. Love it!",
      name: "Cyther"
    }
  ];


  // const handleNext = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.next();
  //   }
  // };

  // const handlePrevious = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.previous();
  //   }
  // };

  return (
    <section className='text-white pt-32 relative bg-[#1F1931]'>
              <div className='absolute sm:flex hidden right-16 gap-5 '>
            <button

                    onClick={() => carouselRef.current.goToSlide(currentIndex - 1)}
                    disabled={currentIndex <= 0}
                    style={{cursor: currentIndex <= 0 ? "not-allowed " :"" }} 
                    className={`w-12 h-12 ${currentIndex <= 0 ? "opacity-50" : "opacity-100"}`}
                >
                    <img src={LeftArrow} alt="Left Arrow" />
                </button>
                <button

onClick={() => carouselRef.current.goToSlide(currentIndex + 1)}
disabled={currentIndex >= reviews.length - responsive.reviews}
style={{ cursor: currentIndex >= reviews.length - responsive.reviews ? "not-allowed" : "" }} 
className={`w-12 h-12 ${currentIndex >= reviews.length - responsive.reviews ? "opacity-50" : "opacity-100"}`}
>
<img src={RightArrow} alt="right Arrow" />
</button>
</div>
      <h2 className='text-center text-[35px]'>Hear from our <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>users</span></h2>
      <div className=''>

        <SliderContainer>
          

          <Carousel
            responsive={{
              superLargeDesktop: {
                breakpoint: { max: 4000, min: 2000 },
                items: responsive.reviews
              },
              desktop: {
                breakpoint: { max: 2000, min: 1024 },
                items: responsive.reviews
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: responsive.reviews
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: responsive.reviews
              }
            }}
            beforeChange={(nextIndex) => handleBeforeChange(nextIndex)}
            ref={carouselRef}
            arrows={false}
            containerClass="carousel-container p-0 m-0"
            swipeable={true}
            draggable={true}
             className='flex justify-start gap-0 sm:p-0 py-5 my-8'
          >
            {reviews.map((item, index) => (
              <div
                key={index}
                className='pr-4'
                style={{
                  position: 'relative',
                  display: 'flex',
                  
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ReviewCard comment={item.comment} name={item.name} />
              </div>
            ))}
          </Carousel>


        </SliderContainer>

      </div>
      <div className='absolute right-16 sm:hidden flex gap-5 '>
            <button

                    onClick={() => carouselRef.current.goToSlide(currentIndex - 1)}
                    disabled={currentIndex <= 0}
                    style={{cursor: currentIndex <= 0 ? "not-allowed " :"" }} 
                    className={`w-10 h-10 ${currentIndex <= 0 ? "opacity-50" : "opacity-100"}`}
                >
                    <img src={LeftArrow} alt="Left Arrow" />
                </button>
                <button

onClick={() => carouselRef.current.goToSlide(currentIndex + 1)}
disabled={currentIndex >= reviews.length - responsive.reviews}
style={{ cursor: currentIndex >= reviews.length - responsive.reviews ? "not-allowed" : "" }} 
className={`w-10 h-10 ${currentIndex >= reviews.length - responsive.reviews ? "opacity-50" : "opacity-100"}`}
>
<img src={RightArrow} alt="right Arrow" />
</button>
</div>
    </section>
  );
};

export default ReviewSection;
