import React from 'react';
import star from "../../../assets/images/star.png";
import quote from "../../../assets/images/quote.webp"
const ReviewCard = ({ comment, name }) => {
    const customStyle = {
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.9)',
    };
    const stars = Array(5).fill(null);

    return (
        <div className='w-auto max-h-[400px] xs:h-[270px] h-auto flex flex-col text-white p-8  rounded-3xl bg-gradient-to-r from-[#161224] via-[#312B43] to-[#171325]  border border-[#413563]'>
            <div className='flex flex-col flex-grow'>
                {/* <img src={quote} alt="quote" className='w-20 h-20 mb-3' /> */}
               
<div className='flex gap-5 '>
<div className='flex flex-col items-start '>
                <h4 className='text-xl font-semibold mb-1 mt-5 '>{name}</h4>
                <div className='flex gap-2 pb-2'>
                    <p>5</p>
                    {stars.map((_, index) => (
                        <img key={index} src={star} alt="Star" className='w-5 h-5 mx-[2px]' />
                    ))}
                </div>
            </div>
            <img src={quote} alt="quote" className='xs:w-20 xs:h-14 w-12 h-10 mt-5'/>
            </div>

                <p className='text-[14px] my-5 flex-grow text-[#B4BCD0]'>
                    {comment}
                </p>
            </div>

        </div>
    );
}

export default ReviewCard;
