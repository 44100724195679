import React, { useEffect, useState } from 'react';
import arrow from "../../assets/images/Group 7348.png";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from './Header';
import { Helmet } from 'react-helmet';
import SliderComponent from './SliderComponent';
import "./BlogDesPage.css";

const BlogDesPage = ({ Category, Data }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return date.toLocaleDateString(undefined, options);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const id = location.pathname.includes('/blog/mental-health')
    ? '/blog/mental-health'
    : location.pathname.includes('/blog/sleep')
    ? '/blog/sleep'
    : location.pathname.includes('/blog/productivity')
    ? '/blog/productivity'
    : location.pathname.includes('/blog/mindfullness')
    ? '/blog/mindfullness'
    : '/blog/well-being';

  const headerOffset = 100; // Adjust this value to match your header's height
  const { detail } = useParams();
  const matchedBlog = Data?.find((item) => item.slug === detail);

  const description = matchedBlog?.content?.rendered?.replace(/<[^>]+>/g, "");

  const [subHeadings, setSubHeadings] = useState([]);

  useEffect(() => {
    // Parse content to extract h2 tags and add IDs
    if (matchedBlog?.content?.rendered) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(matchedBlog.content.rendered, "text/html");

      // Extract headings and assign IDs
      const headings = Array.from(doc.querySelectorAll("h2")).map((heading, index) => {
        const id = `heading-${index}`;
        heading.id = id; // Add an ID to the heading
        return { id, text: heading.textContent };
      });

      // Update the rendered content with IDs
      matchedBlog.content.rendered = doc.body.innerHTML;

      setSubHeadings(headings);
    }
  }, [matchedBlog]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetPosition = element.offsetTop - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className="flex flex-col min-h-screen max-h-full text-white bg-gradient-to-b"
      style={{
        backgroundImage:
          'linear-gradient(to bottom, rgba(0, 0, 0, 1) 5%, rgba(31, 25, 49, 1) 10%, #1F1931 100%)',
      }}
    >
      <Helmet>
        <title>{matchedBlog?.title?.rendered}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={Category} />
        <meta name="author" content="Admin" />
      </Helmet>

      <Header />

      <div className="pt-28"></div>

      <div className="flex flex-col items-center md:pl-[2vw] pl-[5vw] pr-[5vw]">
        <div className="w-full max-w-[1200px] flex gap-10">
          <div className="w-3/12 sm:flex hidden">
            
            <div className="flex flex-col justify-start text-[16px] font-bold">
            <img
              src={arrow}
              className="w-14 h-14 cursor-pointer z-40 mb-28"
              alt="arrow"
              onClick={() => navigate(-1)}
            />
            <div className='flex flex-col gap-6 justify-start text-[16px] font-semibold'>
              <h3 className="underline text-[20px]">In This Article</h3>
              {subHeadings.map((heading, index) => (
                <h4
                  key={index}
                  className="cursor-pointer hover:underline"
                  onClick={() => scrollToSection(heading.id)}
                >
                  {heading.text}
                </h4>
              ))}
            </div>
            </div>
          </div>

          <div className="w-full h-full">
            <h1 className="md:text-[40px] text-[32px] mb-[2vh] font-bold">
              {matchedBlog?.title?.rendered}
            </h1>
            <p className="text-white text-lg mb-[2vh] font-semibold flex gap-8">
              {formatDate(matchedBlog?.date)}
            </p>

            <img
              src={matchedBlog?.yoast_head_json.og_image[0]?.url}
              alt={matchedBlog?.title?.rendered}
              className="rounded-xl inline-block w-full max-h-[500px] h-screen"
            />

            <div
              className="mb-6 text-[16px] text-left text-[#BDBCC0] mt-4 custom-html-container"
              dangerouslySetInnerHTML={{
                __html: matchedBlog?.content?.rendered,
              }}
            />
          </div>
        </div>
        <div className="w-full max-w-[1200px] my-10 pl-[2.5vw]">
        <SliderComponent title={"Recent Posts"} reviews={Data} category={Category} id={id} />
        </div>
      </div>

     
    </div>
  );
};

export default BlogDesPage;
