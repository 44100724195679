import React, { useEffect, useRef, useState } from 'react';
import BlogCard from './BlogCard';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import styled from 'styled-components';
import LeftArrow from "../../../assets/images/leftarrow.svg";
import RightArrow from "../../../assets/images/rightarrow.svg";

const SliderContainer = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
`;

const ArrowButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;

    img {
        width: 60px; 
        height: 60px; 
    }

    &:hover {
        opacity: 0.7;
    }
    &:disabled {
        opacity: 0.4;
    }
`;

const SliderSection = ({items}) => {
    
    const [data,setData]=useState([]);
    

    const navigate = useNavigate();
    const carouselRef = useRef();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [responsive, setResponsive] = useState(getResponsive());
    const [transitionDuration, setTransitionDuration] = useState(100); // default transition duration

    function getResponsive() {
        const width = window.innerWidth;
        if (width >= 4000) {
            return { items: 8.5, padding: "50px" };
        } else if (width >= 3500) {
            return { items: 7.5, padding: "40px" };
        } else if (width >= 3000) {
            return { items: 6.5, padding: "30px" };
        } else if (width >= 2500) {
            return { items: 5.5, padding: "20px" };
        } else if (width >= 2000) {
            return { items: 4.5, padding: "20px" };
        }
        else if (width >= 1700) {
            return { items: 3.5, padding: "20px" };
        }
       
        else if (width >= 1300) {
            return { items: 2.90, padding: "20px" };
        }
        else if (width >= 1200) {
            return { items: 2.5, padding: "15px" };
         }
         else if (width >= 900) {
            return { items: 1.90, padding: "15px" };
         }
         else if (width >= 600) {
                return { items: 1.5, padding: "15px" };
            }
        else {
            return { items: 1, padding: "10px" };
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setResponsive(getResponsive());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleBeforeChange = (nextIndex) => {
        setCurrentIndex(nextIndex);
    };

    const handleExploreClick = () => {
        window.scrollTo(0, 0);
        navigate("/blog");
    };

    const handleArrowClick = (direction) => {
        if (direction === "left") {
          carouselRef.current.goToSlide(currentIndex - 1);
        } else {
          carouselRef.current.goToSlide(currentIndex + 1);
        }
      };
    const truncateHTMLContent = (html, maxLength) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html; // Parse the HTML content
        const plainText = tempDiv.textContent || tempDiv.innerText || ''; // Extract plain text
        return plainText.length > maxLength
          ? `${plainText.substring(0, maxLength)}...`
          : plainText;
      };

    const fetchData = async () => {
        try {
            const response = await fetch('https://blog.soundscape.co.in/wp-json/wp/v2/posts');
            const jsonData = await response.json();
            setData(jsonData);
        } catch (err) {
            console.error("Unable to fetch data:", err);
        } 
    };

    useEffect(() => {
        fetchData();
    console.log(data);
    }, [data]);

    return (
        <div className='text-white relative bg-[#1F1931] '>
            <div className='absolute sm:flex hidden right-32 gap-5 mt-2'>
                <button
                    onClick={() => handleArrowClick('left')}
                    disabled={currentIndex <= 0}
                    style={{ cursor: currentIndex <= 0 ? "not-allowed" : "" }}
                >
                    <img src={LeftArrow} alt="Left Arrow" className={`w-12 h-12 ${currentIndex <= 0 ? "opacity-50" : "opacity-100"}`} />
                </button>
                <button
                    onClick={() => handleArrowClick('right')}
                    disabled={currentIndex >= items.length - responsive.items}
                    style={{ cursor: currentIndex >= items.length - responsive.items ? "not-allowed" : "" }}
                >
                    <img src={RightArrow} alt="Right Arrow" className={`w-12 h-12 ${currentIndex >= items.length - responsive.items ? "opacity-50" : "opacity-100"}`} />
                </button>
            </div>
            <h2 className="text-center text-[35px] mt-32">
                Our <span className='bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Blogs</span>
            </h2>

            <SliderContainer>
                <Carousel
                    responsive={{
                        superLargeDesktop: { breakpoint: { max: 4000, min: 2000 }, items: responsive.items },
                        desktop: { breakpoint: { max: 2000, min: 1024 }, items: responsive.items },
                        tablet: { breakpoint: { max: 1024, min: 464 }, items: responsive.items },
                        mobile: { breakpoint: { max: 464, min: 0 }, items: responsive.items }
                    }}
                    beforeChange={handleBeforeChange}
                    
          
          customTransition="all 0.5s ease"
          transitionDuration={500} // Smooth scrolling duration
    
          
                    ref={carouselRef}
                    arrows={false}
                    itemClass="transition-all duration-100 ease-in-out"
                    swipeable={true} // Enable swiping for smooth user interaction
                    containerClass="carousel-container  m-0"
                    className='flex justify-start gap-0 sm:px-[5vw] px-10 py-5 my-8'
                >
                     {items.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                
                                position: "relative",
                            }}
                            className=''
                        >
                            <BlogCard
                                image={item?.yoast_head_json.og_image[0]?.url}
                                des={truncateHTMLContent(item?.content?.rendered, 90)}
                                title={item?.title?.rendered}
                                category={item.category}
                                id={item?.path + item?.slug}
                            />
                        </div>
                    ))}
                </Carousel>
            </SliderContainer>
            <p className='underline text-xl text-center cursor-pointer mt-2' onClick={handleExploreClick}>See All Blogs</p>
            <div className='mr-16 sm:hidden flex justify-end items-end gap-5'>
                <button
                    onClick={() => handleArrowClick('left')}
                    disabled={currentIndex <= 0}
                    style={{ cursor: currentIndex <= 0 ? "not-allowed " : "" }}
                    className={`w-10 h-10 ${currentIndex <= 0 ? "opacity-50" : "opacity-100"}`}
                >
                    <img src={LeftArrow} alt="Left Arrow" />
                </button>
                <button
                    onClick={() => handleArrowClick('right')}
                    disabled={currentIndex >= items.length - responsive.items}
                    style={{ cursor: currentIndex >= items.length - responsive.items ? "not-allowed" : "", }}
                    className={`w-10 h-10 ${currentIndex >= items.length - responsive.items ? "opacity-50" : "opacity-100"}`}
                >
                    <img src={RightArrow} alt="Right Arrow" />
                </button>
            </div>
        </div>
    );
};

export default SliderSection;
