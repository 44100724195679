import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import NewTopPlaylist from './components/NewTopPlaylist/NewTopPlaylist';
import NewMood from './components/NewMood/NewMood';
import NewStories from './components/NewStories/NewStories';
import BrainWaves from './components/Brainwaves/BrainWaves';
import NewGuided from './components/NewGuided/NewGuided';
import Radio from './components/Radio/Radio';
import NewBlogPage from './components/NewBlog/NewBlogPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import GAListener from './GAListener';
import { initGA } from './initGA';
import ConditionalFooter from './components/ConditionalFooter';
import BlogDesPage from './components/NewBlog/BlogDesPage';
import BlogCategoryPage from './components/NewBlog/BlogCategoryPage';

const App = () => {
  const [data, setData] = useState([]);
  const [mentalHealth, setMentalHealth] = useState([]);
  const [sleep, setSleep] = useState([]);
  const [workLife, setWorkLife] = useState([]);
  const [meditation,setMeditation]=useState([]);
  const [health,setHealth]=useState([]);
  const [AllData,setAllData]=useState([]);
  // Function to fetch data
  const fetchData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BLOG_URL);
      const jsonData = await response.json();
      const categorizedData = jsonData.map(post => {
        if (post.class_list.includes('category-mental-health')) {
          return { ...post, category: 'Mental Health' ,path:"/mental-health/"};
        } else if (post.class_list.includes('category-sleep')) {
          return { ...post, category: 'Sleep' ,path:"/sleep/"};
        } else if (post.class_list.includes('category-worklife')) {
          return { ...post, category: 'Productivity',path:"/productivity/" };
        } else if (post.class_list.includes('category-meditation')) {
          return { ...post, category: 'Mindfullness',path:"/mindfullness/" };
        } else if (post.class_list.includes('category-health-wellbeing')) {
          return { ...post, category: 'Well Being' ,path:"/well-being/"};
        } else {
          return { ...post, category: 'Uncategorized' };
        }
      });
  
      setData(categorizedData);
  
      // Categorize data
      const mentalHealthPosts = categorizedData.filter(post => post.category === 'Mental Health');
      const sleepPosts = categorizedData.filter(post => post.category === 'Sleep');
      const workLifePosts = categorizedData.filter(post => post.category === 'Productivity');
      const meditationPosts = categorizedData.filter(post => post.category === 'Mindfullness');
      const healthPosts = categorizedData.filter(post => post.category === 'Well Being');
  
      setMentalHealth(mentalHealthPosts);
      setSleep(sleepPosts);
      setWorkLife(workLifePosts);
      setMeditation(meditationPosts);
      setHealth(healthPosts);
      const allCombinedData = [
        ...mentalHealthPosts,
        ...sleepPosts,
        ...workLifePosts,
        ...meditationPosts,
        ...healthPosts,
      ];
  
      setAllData(allCombinedData); 
      console.log("Hellivfdvv",mentalHealth)
    } catch (err) {
      console.error("Unable to fetch data:", err);
    }
  };

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    fetchData();
  }, [data]);

  return (
    <div className='wix-madefor-text-bold overflow-hidden'>
      <Router>
        <GAListener />
        <Routes>
          <Route path='/' element={<LandingPage combinedData={AllData} />} />
          <Route path='/top-playlist' element={<NewTopPlaylist />} />
          <Route path='/moods' element={<NewMood />} />
          <Route path='/stories' element={<NewStories />} />
          <Route path='/brainwaves' element={<BrainWaves />} />
          <Route path='/guided-meditations' element={<NewGuided />} />
          <Route path='/radio' element={<Radio />} />
          <Route path='/blog' element={<NewBlogPage combinedData={AllData} mentalHealth={mentalHealth} sleep={sleep} productivity={workLife} health={health} mindfullness={meditation}/>} />
          <Route path='/blog/mental-health' element={<BlogCategoryPage Category={"Mental Health"} Data={mentalHealth}/>} />
          <Route path='/blog/mental-health/:detail' element={<BlogDesPage Category={"Mental Health"} Data={mentalHealth}/>} />
          <Route path='/blog/sleep' element={<BlogCategoryPage Category={"Sleep"} Data={sleep} />} />
          <Route path='/blog/sleep/:detail' element={<BlogDesPage Category={"Sleep"} Data={sleep}/>} />
          <Route path='/blog/productivity' element={<BlogCategoryPage Category={"Productivity"} Data={workLife}/>} />
          <Route path='/blog/productivity/:detail' element={<BlogDesPage Category={"Productivity"} Data={workLife}/>} />
          <Route path='/blog/well-being' element={<BlogCategoryPage Category={"Well Being"} Data={health}/>} />
          <Route path='/blog/well-being/:detail' element={<BlogDesPage Category={"Well Being"} Data={health}/>} />
          <Route path='/blog/mindfullness' element={<BlogCategoryPage Category={"Mindfullness"} Data={meditation}/>} />
          <Route path='/blog/mindfullness/:detail' element={<BlogDesPage Category={"Mindfullness"} Data={meditation}/>} />
          <Route path='/privacy' element={<PrivacyPolicy />} />
          <Route path='/about-soundscape' element={<AboutUs />} />
          <Route path='/contact' element={<ContactUs />} />
        </Routes>
        <ConditionalFooter />
      </Router>
    </div>
  );
};

export default App;
