import React from 'react';
import logo from "../assets/images/Group 123 (1).png";
import Name from "../assets/images/Frame 33643.png";
import scanner from "../assets/images/Soundscape playstore 1 (1).webp";
import playstoreIcon from "../assets/images/image 314.webp";
import store from "../assets/images/pngegg (1) 1.webp";
import { useNavigate } from 'react-router-dom';

const NewFooter = () => {
    const navigate = useNavigate();

    const handleScrollClick = (path) => {
        window.scrollTo(0, 0);
        navigate(path);
    };

    const handleNewTabClick = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className='flex flex-col items-center bg-black'>
            <div className='w-full max-w-[1200px] py-10'>
                <div className='flex justify-center flex-col sm:flex-row items-center text-white '>
                    {/* Logo Section */}
                    <div className='flex-1 flex items-start justify-between gap-2  md:pl-0 sm:pl-5'>
                        <img src={logo} alt='logo' className='w-auto h-32' />
                        <img src={Name} alt="soundscape" className='w-auto ' />
                    </div>

                    {/* Links Section */}
                    <div className='flex-1 flex flex-col items-center space-y-6 mt-16 text-lg font-semibold cursor-pointer md:ml-24 sm:ml-16 ml-0'>
                        <div onClick={() => handleNewTabClick('/about-soundscape')} className='text-left'>About Us</div>
                        <div onClick={() => handleScrollClick('/blog')} className='text-left'>Blogs</div>
                        <div onClick={() => handleNewTabClick('/privacy')} className='text-left text-[14px]'>Privacy Policy</div>
                        
                    </div>

                    {/* Playstore Section */}
                    <div className='flex-1 flex sm:items-start items-center  mt-8 justify-center gap-4'>
                        <img src={scanner} alt="QR code" className='md:w-24 md:h-24 sm:w-20 sm:h-20 sm:flex hidden' />
                        <div className='flex items-center  gap-3 cursor-pointer md:mt-4 sm:mt-2 mt-10' onClick={() => handleNewTabClick('https://play.google.com/store/apps/details?id=com.music.focusflow&pcampaignid=web_share')}>
                            <img src={playstoreIcon} alt='playstoreIcon' className='w-7 h-7 mt-3' />
                            <img src={store} alt="text" className='w-28' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewFooter;
