import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const BlogCard = ({ image, category, title, date, time, path, slug }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {detail}=useParams();
  const slugroute = path ? `${path}/${slug}` : `/${slug}`;

  const handleExploreClick = () => {
    window.scrollTo(0, 0);
    const currentPath = location.pathname;
    navigate(path ? slugroute : `${currentPath}${slugroute}`);
  };

  return (
    <div
      className="flex flex-col gap-5 w-80 text-white h-auto rounded-xl hover:bg-[#362F45] pb-10 cursor-pointer group"
      onClick={() => {
       
          handleExploreClick(); // Directly navigate if `id` is not present
      }}
    >
      {/* Image */}
      <div className="overflow-hidden rounded-t-xl group-hover:rounded-t-xl transition-all duration-300">
        <img
          src={image}
          className="w-full h-40 object-cover transition-all duration-300 group-hover:rounded-t-xl group-hover:opacity-80"
          alt="blog visual"
        />
      </div>

      {/* Content */}
      <div className="group-hover:px-4 transition-all duration-300">
        {/* Category */}
        <p className="text-[15px] font-semibold text-[#FFB457]">{category}</p>

        {/* Title */}
        <h3 className="text-xl mt-2 pb-2 bg-gradient-to-r from-white to-[#999999] text-transparent bg-clip-text">
          {title}
        </h3>

        {/* Date and Time */}
        <div className="flex gap-6 text-[#B4BCD0] text-[12px] font-semibold">
          <p>{date}</p>
          <p>{time}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
