import React, { useRef, useState, useEffect } from 'react';
import Angry from "../../../assets/images/Angry.png";
import Distracted from "../../../assets/images/Distracted.png";
import Sad from "../../../assets/images/Sad.png";
import Ani from "../../../assets/images/Ani.png";
import Tired from "../../../assets/images/Frame 33623.png";
import Romantic from "../../../assets/images/Frame 33624.png";

const MoodSection = () => {
  const moods = [
    { image: Angry, name: "Angry" },
    { image: Distracted, name: "Distracted" },
    { image: Sad, name: "Sad" },
    { image: Ani, name: "Anxious" },
    { image: Tired, name: "Tired" },
    { image: Romantic, name: "Romantic" }
  ];

  const [datamood, setDatamood] = useState([...moods, ...moods, ...moods, ...moods, ...moods]);
  const scrollContainerRef = useRef(null);

  const addMoreImages = () => {
    setDatamood((prev) => [...prev, ...moods]);
  };

  const handleScroll = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
      if (scrollLeft + clientWidth >= scrollWidth - 1) {
        addMoreImages();
      }
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className="text-white  bg-[#1F1931]">
      <h2 className="text-center px-[5vw] text-[35px] mb-8 mt-20">
        Sounds For Every <span className='bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Mood</span>
      </h2>
      <div className="relative">
        
        <div className="absolute z-20 top-0 bottom-0 -left-24  w-44 h-48 bg-gradient-to-r from-[#1F1931]  via-[#1F1931] to-transparent pointer-events-none"></div>
        <div className="absolute z-20 top-0 bottom-0 -right-24 w-44 h-48  bg-gradient-to-l from-[#1F1931] via-[#1F1931] to-transparent pointer-events-none"></div>

        <div ref={scrollContainerRef} className="scroll-container overflow-x-auto whitespace-nowrap mt-8">
          <div className="scroll-background-left-img flex justify-between gap-14">
            {datamood.map((mood, index) => (
              <div 
                key={index} 
                className={`relative cursor-default`}
              >
                <img
                  src={mood.image}
                  alt={mood.name}
                  className={`w-44 z-10 h-44`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoodSection;
