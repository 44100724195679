import React from 'react';
import { useNavigate } from 'react-router-dom';

const BlogCard = ({ image, des,title, category ,id }) => {
  const navigate = useNavigate();

  const handleExploreClick = () => {
    // Scroll to the top when navigating to a new route
    window.scrollTo(0, 0);
    navigate('/blog' + id);
  };

  return (
    <div
    className="bg-gradient-to-r from-[#0E282C] via-[#193B40] to-[#0E282C] w-[440px] sm:h-[480px] h-[545px] border border-[#1F4347] rounded-3xl flex flex-col justify-between cursor-pointer group group-hover:from-white/30 group-hover:via-white/20 group-hover:to-white/30"
    onClick={() => {
      handleExploreClick();
    }}
  >
    {/* Image */}
    <img
      src={image}
      className="w-full h-56 rounded-t-3xl object-cover group-hover:opacity-70"
      alt="blog visual"
    />
  
    {/* Content */}
    <div className="p-[14px] flex flex-col flex-grow">
      {/* Category */}
      <p className="text-[15px] font-semibold mt-2 ">{category}</p>
  
      {/* Title */}
      <h3 className="text-xl font-medium mt-4 pb-[7px]">{title}</h3>
  
      {/* Description */}
      <p className="text-[14px] mt-2 text-[#B4BCD0] flex-grow">{des}</p>
  
      {/* Button */}
      <button
        className="font-bold mb-2 w-fit px-6 py-2 bg-white sm:text-[14px] text-[14px] hover:bg-[#EEEEEE] shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] text-black rounded-full"
        onClick={handleExploreClick}
      >
        Read Complete Blog
      </button>
    </div>
  </div>
  
  );
};

export default BlogCard;
