import React from 'react'
import Header from '../Header'
import HeadingSection from './Sections/HeadingSection'
import MoodSection from './Sections/MoodSection'
import FAQSection from './Sections/FAQSection'


const NewMood = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b" style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 1%, rgba(31, 25, 49, 1) 20%, #1F1931 100%)' }}>
        <Header/>
        <HeadingSection/>
        <MoodSection/>
<FAQSection/>
    </div>
  )
}

export default NewMood