import React from 'react'
import HeadingSection from './Sections/HeadingSection'
import StoriesSection from './Sections/StoriesSection'
import Header from '../Header'
import FAQSection from './Sections/FAQSection'


const NewStories = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b" style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, #1F4347 20%, #1F4347 100%)' }}>
        <Header/>
<HeadingSection/>
<StoriesSection/>
<FAQSection/>

    </div>
  )
}

export default NewStories