import React, { useEffect, useRef, useState } from 'react';
import Angry from "../../../assets/images/Angry.png";
import Distracted from "../../../assets/images/Distracted.png";
import Tired from "../../../assets/images/Frame 33623.png";
import { useLocation } from 'react-router-dom';
import Player from '../../Player';

const MoodSection = () => {
    const location = useLocation();
    const moodName = location.hash.substring(1);
    const [playingTrack, setPlayingTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [moodplaylist, setMoodPlaylist] = useState(null);
    const width = "sm:w-full w-fit h-fit";
    const moodRefs = useRef({});

    const playlist = [
        { songName: Tired, name: "Tired", songs: moodplaylist?.result[1]?.audios,duration:["12:50","14:32","20:00","19:40"] },
        { songName: Angry, name: "Angry", songs: moodplaylist?.result[5]?.audios,duration:["02:35","02:19","01:56","02:11"] },
        
        { songName: Distracted, name: "Distracted", songs: moodplaylist?.result[0]?.audios,duration:["03:50","04:41","03:08","01:52"] },
       
    ];

    const handleTrackClick = (songName) => {
        if (playingTrack === songName) {
            setIsPlaying(false);
        } else {
            setPlayingTrack(songName);
            setIsPlaying(true);
        }
    };

    useEffect(() => {
        if (!isPlaying) {
            setPlayingTrack(null);
        }
    }, [isPlaying]);

    const scrollToMood = () => {
        if (moodName && moodRefs.current[moodName]) {
            moodRefs.current[moodName].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    useEffect(() => {
        if (moodplaylist) {
            scrollToMood();
        }
    }, [moodplaylist, moodName]);

    const fetchData = async () => {
        try {
            const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/web_moods`);
            const result = await data.json();
            setMoodPlaylist(result);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='pt-[85px]  items-center flex flex-col md:px-[5vw] sm:px-[3vw] px-[5vw] relative'>
        <div className='w-full max-w-[1200px] relative text-white'>
<h2 className='text-center text-[35px] ' id={playlist[0]?.name}>Playlists For Every <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Emotion</span></h2>
                    {moodplaylist&&playlist.map((i, index) => (
                        <section
                            key={index}
                            ref={(el) => (moodRefs.current[i.name] = el)}
                        >
                            <Player
                                image={i.songName}
                                width={width}
                                name={i.name}
                                duration={i.duration}
                                songs={i.songs}
                                onPlayTrack={handleTrackClick}
                                isPlaying={playingTrack === i.name && isPlaying}
                                setIsPlaying={setIsPlaying}
                            />
                        </section>
                    ))}
                </div>
            </div>
    );
};

export default MoodSection;
