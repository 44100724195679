import React, { useState,useEffect } from 'react'
import Player from '../../Player';

const TopPlaylistPlayer = () => {
    const [playingTrack, setPlayingTrack] = useState(null); 
    const [isPlaying, setIsPlaying] = useState(false);
    const width = "sm:w-full w-[300px]  sm:h-64 ";
    const [topPlaylist,setTopPlaylist]=useState(null)
    const tracksplaylist = [
        { songName: topPlaylist?.result[0].playlist_name, song:process.env.REACT_APP_MEDIA_URL+topPlaylist?.result[0].image_path,songs:topPlaylist?.result[0].audios,duration:["03:33","20:02","18:50","20:00"] },
        { songName: topPlaylist?.result[3].playlist_name, song:process.env.REACT_APP_MEDIA_URL+topPlaylist?.result[3].image_path,songs:topPlaylist?.result[3].audios,duration:["02:55","02:27","22:50","02:29"] },
        { songName: topPlaylist?.result[4].playlist_name, song:process.env.REACT_APP_MEDIA_URL+ topPlaylist?.result[4].image_path,songs:topPlaylist?.result[4].audios ,duration:["01:52","03:33","01:11","05:06"]},
    ];

    const handleTrackClick = (songName) => {
        if (playingTrack === songName) {
            // If the track is already playing, pause it
            setIsPlaying(false); 
        } else {
            // If another track is clicked, start playing it
            setPlayingTrack(songName); 
            setIsPlaying(true); 
        }
    };

    // Optional: Reset playingTrack when isPlaying is set to false
    useEffect(() => {
        if (!isPlaying) {
            setPlayingTrack(null);
        }
    }, [isPlaying]);

    const fetchData=async()=>{
        const data=await fetch(process.env.REACT_APP_BACKEND_URL+"/web_top-playlist");
        const result=await data.json();
        console.log(result);
        setTopPlaylist(result);
    }
    
     useEffect(()=>{
       fetchData();
     },[topPlaylist])

  return (
    <div className='pt-[80px]  items-center flex flex-col md:px-[5vw] sm:px-[3vw] px-[5vw] relative'>
                    <div className='w-full max-w-[1200px] relative text-white'>
        <h2 className='text-center text-[35px] ' id={tracksplaylist[0]?.songName}>Playlists For Every  <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Moment</span></h2>
        
                        {/* {console.log(tracksplaylist[0].song)} */}
                        {topPlaylist&&tracksplaylist.map((track, index) => (
                        <Player
                            key={index} 
                            image={track.song} 
                            name={track.songName} 
                            width={width} 
                            duration={track.duration}
                            songs={track.songs}
                            onPlayTrack={handleTrackClick}
                            isPlaying={playingTrack === track.songName && isPlaying} 
                            setIsPlaying={setIsPlaying} 
                        />
                    ))}
                    {/* {topPlaylist?tracksplaylist.map((track, index) => (
                        <Track 
                            key={index} 
                            image={track.song} 
                            name={track.songName} 
                            width={width} 
                            songs={track.songs}
                            onPlayTrack={handleTrackClick}
                            isPlaying={playingTrack === track.songName && isPlaying} 
                            setIsPlaying={setIsPlaying} 
                        />
                    )):(<div className='flex flex-col items-center'><ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']}
                    /></div>)} */}
                    </div>
                    

                </div>
  )
}

export default TopPlaylistPlayer