// Track.jsx
import React, { useEffect, useState, useRef } from "react";
import { Howl } from "howler";
import Birds from "../assets/images/Group 7404.webp";
import fire from "../assets/images/Group 7402.webp";
import Thunder from "../assets/images/Group 7403.webp";
import pauseIcon from "../assets/images/Frame 33702.svg";
import minusIcon from "../assets/images/minussign.png";
import addIcon from "../assets/images/Group 7353.svg"
import playIcon from "../assets/images/Group 7434.svg";
import "./Track.css";
import { useLocation } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";

const Player = ({
    image,
    width,
    songs,
    name,
    duration,
    onPlayTrack,
    isPlaying,
    setIsPlaying
}) => {
   
    const location = useLocation();
    const isStory = location.pathname !== "/stories";
    const [tracks, setTracks] = useState(() => 
        songs.map((song, index) => {
            const audioPath = isStory 
                ? process.env.REACT_APP_MEDIA_URL + song?.audio_path 
                : process.env.REACT_APP_MEDIA_URL + song?.episode_path;
            const audioName = isStory ? song?.audio_name : `Episode ${index + 1}`;

            return {
                id: index + 1,
                title: audioName || `Episode ${index + 1}`, // Default to Episode if title missing
                progress: 0,
                currentTime: "00:00",
                duration: duration[index]||"00:00",
                song: audioPath,
            };
        })
    );

    const [currentTrack, setCurrentTrack] = useState(null);
    const [selectedEffects, setSelectedEffects] = useState([]);
    const [effectsSounds, setEffectsSounds] = useState([]);
    const [sideEffectSound,setSideEffectSound]=useState(null);

    const audioRef = useRef(null);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const playTrack = (trackNumber) => {
        if (currentTrack === trackNumber) {
            // Toggle play/pause for the same track
            setIsPlaying(!isPlaying);
            onPlayTrack(name); 
        } else {
            // Play a different track
            setCurrentTrack(trackNumber);
            onPlayTrack(name); 
            setIsPlaying(true);
        }
    };

    const handleEffectClick = (effectName) => {
        setSelectedEffects(prevSelected => {
            if (prevSelected.includes(effectName)) {
                return prevSelected.filter(e => e !== effectName);
            }
            if (prevSelected.length >= 2) {
                alert("You can select at most 2 sound effects.");
                return prevSelected;
            }
            return [...prevSelected, effectName];
        });
    };

    useEffect(() => {
        if (currentTrack === null || !isPlaying) {
            // Stop all effects if no track is playing
            effectsSounds.forEach(sound => sound.stop());
            setEffectsSounds([]);
            return;
        }

        // Stop any existing effects before starting new ones
        effectsSounds.forEach(sound => sound.stop());

        // Start new effects based on selectedEffects
        const newEffectsSounds = selectedEffects.map(effectName => {
            const effectAudio = {
                Fire: process.env.REACT_APP_MEDIA_URL+sideEffectSound?.result[0]?.audio_path,
                Rain: process.env.REACT_APP_MEDIA_URL+sideEffectSound?.result[2]?.audio_path,
                Bird: process.env.REACT_APP_MEDIA_URL+sideEffectSound?.result[1]?.audio_path,
            }[effectName];

            return new Howl({
                src: [effectAudio],
                volume: 0.3,
                loop: true
            });
        });

        setEffectsSounds(newEffectsSounds);
        newEffectsSounds.forEach(sound => sound.play());

        // Cleanup effects when dependencies change or component unmounts
        return () => newEffectsSounds.forEach(sound => sound.stop());
    }, [selectedEffects, currentTrack, isPlaying]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (audioRef.current && audioRef.current.duration && currentTrack !== null) {
                const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
                const currentTime = formatTime(audioRef.current.currentTime);
                const duration = formatTime(audioRef.current.duration);

                setTracks((prevTracks) => prevTracks.map((track) => 
                    track.id === currentTrack
                        ? { ...track, progress, currentTime, duration }
                        : track
                ));
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [audioRef, currentTrack, isPlaying]);

    useEffect(() => {
        if (isPlaying && currentTrack !== null && audioRef.current) {
            audioRef.current.play();
        } else if (!isPlaying && audioRef.current) {
            audioRef.current.pause();
        }
    }, [isPlaying, currentTrack]);

    useEffect(() => {
        if (!isPlaying) {
            setCurrentTrack(null);
            setSelectedEffects([]);
        }
    }, [isPlaying]);

    const handleProgressChange = (event, trackNumber) => {
        if (audioRef.current) {
            const newTime = (event.target.value / 100) * audioRef.current.duration;
            audioRef.current.currentTime = newTime;
        }

        setTracks((prevTracks) =>
            prevTracks.map((track) => 
                track.id === trackNumber
                    ? { ...track, progress: event.target.value }
                    : track
            )
        );
    };

    const handleSoundscapeClick = () => {
        window.open('https://play.google.com/store/apps/details?id=com.music.focusflow&pcampaignid=web_share', '_blank');
    };

    const fetchData=async()=>{
        const data=await fetch(process.env.REACT_APP_BACKEND_URL+"/web_effects");
        const result=await data.json();
        // console.log(result);
        setSideEffectSound(result);
        // console.log(sideEffectSound?.result)
    }

    useEffect(()=>{
       fetchData();
    },[sideEffectSound])

    return (
        <div className="pt-[80px]  text-white"  >

            
            <div className={`sm:flex sm:flex-row flex-col ${location.pathname!=="/top-playlist"?"sm:justify-center sm:items-center":""}  relative ${isStory ? "" : ""} md:gap-14  sm:gap-10 gap-5 `}>
            <div className="relative z-40 sm:w-4/12 ">
    {/* <h4 className={`text-3xl font-bold sm:hidden flex mb-2`}>{name}</h4> */}

    <div className={`${width} relative `}>
  <img
    src={image}
   
    className={` rounded-2xl w-full h-full object-cover`}
    alt={name}
  />
 {location.pathname!=="/moods" && <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-80 text-white text-center py-3 rounded-b-2xl">
    {name}
  </div>}
</div>

</div>


                <div className={`sm:w-8/12 ${location.pathname!=="/top-playlist"?"md:gap-8 gap-4 ":"md:gap-0 sm:gap-4 gap-0"}  flex flex-col justify-between `}>
                    {/* Update the className based on isPlaying prop */}
                
                    {/* <h4 className={`${isPlaying ? "md:text-3xl sm:text-[22px] text-[15px]" : "md:text-[45px] sm:text-[35px] xs:text-[25px] text-[18px]"} font-bold  sm:flex hidden`}>{name}</h4> */}
                    
                

            
                    <div className="flex flex-wrap md:gap-8 gap-4  sm:mt-0 mt-5">
                        {tracks.map((track) => {
                            return track.song!=="http://testdocss.boostproductivity.onlineundefined"?(<div
                                key={track.id}
                                className={`items-center rounded-2xl  z-20 bg-white/20 ${currentTrack === track.id ? 'h-auto w-auto ' : ' h-auto  hover:bg-white/20  hover:border-white hover:border'}  md:px-4 sm:px-2 md:py-3 sm:py-1 p-2 `}
                                // style={customStyle}
                            >
                                {/* {console.log(track.song)} */}
                                <div className="flex z-30">
                                    <button
                                        className="bg-transparent text-white focus:outline-none mr-4"
                                        onClick={() => {
                                            playTrack(track.id);
                                        }}
                                    >
                                        {/* Correct the icon logic: show pauseIcon when playing, playIcon otherwise */}
                                      {(track.duration==="00:00"&& currentTrack===track.id)? <ColorRing
                                        visible={true}
                                        height="40"
                                        width="40"
                                        ariaLabel="color-ring-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="color-ring-wrapper"
                                        colors={['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']}
                                        />: <img
                                            src={isPlaying && currentTrack === track.id ?  playIcon :pauseIcon }
                                            width={40}
                                            alt="Play/Pause"
                                            className="cursor-pointer"
                                        />}
                                    </button>
                                    <div className="">
                                        <span className={`text-white md:text-sm sm:text-xs  py-2 pr-2 cursor-default "}`}>{track.title}</span>
                                            <div className=" flex  md:text-[12px] sm:text-xs w-full mt-2 cursor-default">
                                            <span className="text-white">{track.currentTime}</span>
                                            <div className="-mt-1 pr-3">
                                                <input
                                                    type="range"
                                                    min="0"
                                                    max="100"
                                                    value={track.progress}
                                                    onChange={(e) => handleProgressChange(e, track.id)}
                                                    className={`${isPlaying&&currentTrack===track.id ? "playing" : ""}`}
                                                    style={{ '--progress': `${track.progress}%` }}
                                                />
                                                </div>
                                                {/* <div className="justify-between flex mt-1 mr-4 ml-2 cursor-default"> */}
                                                   
                                                    <span className="text-white">{track.duration}</span>
                                                {/* </div> */}
                                            </div>
                                    
                                    </div>
                                </div>

                            </div>):""
})}
                        <audio
                            src={tracks.find(track => track.id === currentTrack)?.song}
                            autoPlay={isPlaying && currentTrack !== null}
                            ref={audioRef}
                            onEnded={() => setIsPlaying(false)}
                            hidden
                            preload="auto" // Preload the song
                            onLoadedMetadata={() => {
                                const track = tracks.find((t) => t.id === currentTrack);
                                if (track) {
                                    const duration = formatTime(audioRef.current.duration);
                                    setTracks((prevTracks) =>
                                        prevTracks.map((t) =>
                                            t.id === track.id ? { ...t, duration } : t
                                        )
                                    );
                                }
                            }}
                        />
                    </div>
                    <div className={`inline-flex gap-3 sm:mt-0 mt-4 ${isPlaying ? "w-fit bg-[#655F70] rounded-full pr-6" : ""}`}>
    <button
        className="px-6 py-3 cursor-pointer text-[14px] hover:bg-[#EEEEEE] font-bold rounded-full bg-white shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] text-black w-fit"
        onClick={() => {
            handleSoundscapeClick();
        }}
    >
        Listen more
    </button>

    {isPlaying && (
        <div className="flex gap-4 justify-center items-center py-1">
        {[
            { name: 'Fire', image: fire },
            { name: 'Rain', image: Thunder },
            { name: 'Bird', image: Birds },
        ].map((effect) => (
            <div key={effect.name} className="relative text-center">
                <img
                    src={
                        selectedEffects.includes(effect.name)
                            ? `${effect.image}?v=${new Date().getTime()}`
                            : effect.image
                    }
                    alt={effect.name}
                    className="w-9 h-9 rounded-md cursor-pointer z-50"
                    onClick={() => handleEffectClick(effect.name)}
                />
                {selectedEffects.includes(effect.name) ? (
                    <img
                        src={minusIcon}
                        alt="Minus"
                        className="absolute top-0 right-0 w-[10px] h-[10px] rounded-full bg-white cursor-pointer"
                        onClick={() => handleEffectClick(effect.name)}
                    />
                ) : (
                    <img
                        src={addIcon}
                        alt="Add"
                        className="absolute top-0 right-0 w-[10px] h-[10px] rounded-full bg-white cursor-pointer"
                        onClick={() => handleEffectClick(effect.name)}
                    />
                )}
            </div>
        ))}
    </div>
    )}
</div>

                </div>
            </div>
        </div>
    );

};

export default Player;








