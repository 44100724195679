
import React from 'react';
import { useLocation } from 'react-router-dom';
import NewFooter from './NewFooter';

const ConditionalFooter = () => {
  const location = useLocation();
  const noFooterRoutes = ['/privacy']; 

  const shouldHideFooter = noFooterRoutes.includes(location.pathname);
  
  return shouldHideFooter ? null : <NewFooter />;
};

export default ConditionalFooter;
