import React from 'react';
import PlayIcon from '../../assets/images/PlayIcon.svg';
import PauseIcon from "../../assets/images/Group 7348 (2).svg";
import { ColorRing } from "react-loader-spinner";

const BrainwaveCard = ({ id, image, BackgroundCardImage, songName, audio, onPlayPause, playSongId, checkButton, frequency, color1, color2,loading }) => {
    console.log(color1)
  return (
    <div className={`z-30 relative ${checkButton ? "w-44" : "w-36"} group cursor-pointer`} onClick={() => {
      onPlayPause(audio, id);
    }}>
      <div className='relative'>
        {/* Background Image */}
        {checkButton && (
          <div>
            <img
              src={BackgroundCardImage}
              className={`absolute inset-0 z-10 w-full mt-12 transition-opacity duration-300 ${id === playSongId ? "opacity-50" : ""} group-hover:opacity-50`}
              alt='Background Card'
            />
            {/* Main Image */}
            <img
              src={image}
              width={100}
              className={`relative z-20 transition-opacity duration-300 group-hover:opacity-50 ml-[38px] ${id === playSongId ? "opacity-50" : "opacity-80"}`}
              alt={songName}
            />
          </div>
        )}
        {!checkButton && (
          <div className="relative">
            {/* Main Image */}
            <img
              src={image}
              className={`z-20 transition-opacity duration-300 w-full h-36 rounded-md object-cover ${id === playSongId ? "opacity-50" : "opacity-80"}`}
              alt={songName}
            />
            {/* Color Overlay */}
            <div
              style={{
               
                opacity: 0.4,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 25,
                borderRadius: '8px',
                background: `linear-gradient(to left, ${color1}, ${color2})`
              }}
              className={`pointer-events-none  `}
            ></div>
          </div>
        )}
        {/* Play Icon */}
       {!loading &&<div className={`absolute inset-0 flex items-center justify-center z-40 ${id === playSongId ? "opacity-100" : "opacity-0 group-hover:opacity-100"} transition-opacity duration-300`}>
          <img src={id === playSongId ? PauseIcon : PlayIcon} alt={'Play Icon'} className='w-16 h-16' />
        </div>
}
{
  loading && id===playSongId &&<div className='absolute inset-0 flex items-center justify-center z-40 transition-opacity duration-300'> <ColorRing
  visible={true}
  height="80"
  width="80"
  ariaLabel="color-ring-loading"
  wrapperStyle={{}}
  wrapperClass="color-ring-wrapper"
  colors={['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']}
  />
  </div>
}
       {checkButton&& <div className={`absolute inset-0 flex items-center z-30 justify-center ${id === playSongId ? "opacity-50" : ""} group-hover:opacity-50`}>
          <img src={frequency} alt={'frequency'} className='w-16 h-6' />
        </div>
}
{
    !checkButton&&<div className={`absolute inset-0 flex items-center z-30 justify-center ${id === playSongId ? "opacity-50" : ""} group-hover:opacity-50`}>
    <p className='font-extrabold text-3xl text-white' >{frequency}</p>
  </div>
}
      </div>
      <p className={`${checkButton ? "text-start mx-4 mt-4 text-white" : "text-center font-semibold mt-2 text-[#B4BCD0]"}  z-20 relative text-[15px]`}>{songName}</p>
    </div>
  );
};

export default BrainwaveCard;
