import React, { useState, useEffect, useRef } from 'react';
import classic from"../../assets/images/classic rocks.svg";
import radio from"../../assets/images/radio masti.svg";
import livesong from"../../assets/images/80slive song.svg";
import supercool from"../../assets/images/super cool song.svg";
import Header from '../Header';
import RadioCard from './RadioCard';
import LiveIndicator from './LiveIndicator';
import FAQSection from './FAQSection';
import { useNavigate } from 'react-router-dom';
const Radio = () => {
    const navigate =useNavigate();
    const [radioPlaylist, setRadioPlaylist] = useState(null);
    const [currentAudio, setCurrentAudio] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [songId,setSongId]=useState(null);
    const audioRef = useRef(null);
    const audio_urls=["https://stream-161.zeno.fm/zs3m3yxjr7buv","https://stream-163.zeno.fm/88thk459f0hvv","https://server.mixify.in/listen/new_hits/radio.mp3","https://classicrock.streeemer.com/listen/classic_rock/radio.aac","https://stream.80sa.live/80s-alive.mp3","https://19353.live.streamtheworld.com/977_HITS_SC"]

    // Fetch radio data
    const fetchData = async () => {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/web_radio/");
        const result = await response.json();
        console.log(result);
        setRadioPlaylist(result);
    };

    // Fetch data when the component mounts
    useEffect(() => {
        fetchData();
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null; // Clean up the audio reference
                setIsPlaying(false);
                setCurrentAudio(null);
                setSongId(null);
            }
        };
    }, []);

    // Handle play/pause functionality
    const [loading, setLoading] = useState(false);

    const handlePlayPause = (audioUrl, id) => {
      if (currentAudio === audioUrl) {
        if (isPlaying) {
          audioRef.current.pause();
          setIsPlaying(false);
          setSongId(null);
        } else {
          audioRef.current.play();
          setIsPlaying(true);
          setSongId(id);
        }
      } else {
        if (audioRef.current) {
          audioRef.current.pause();
        }
        setSongId(id);
        setLoading(true); // Show loader while new audio is being prepared
        const newAudio = new Audio(audioUrl);
  
        // Set up event listener for when the audio is ready to play
        newAudio.oncanplaythrough = () => {
          setLoading(false); // Hide loader when audio is ready
          newAudio.play();
          setIsPlaying(true);
         
          setCurrentAudio(audioUrl);
        };
  
        newAudio.onerror = () => {
          setLoading(false); // Hide loader in case of error
          console.error("Audio failed to load.");
        };
  
        audioRef.current = newAudio;
      }
    };
    
    return (
        <div className=" text-white bg-gradient-to-b" style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 1%, rgba(31, 25, 49, 1) 20%, #1F1931 100%)' }}>
            <div className=' min-h-screen max-h-full'>
                <Header />
<div className='flex flex-col items-center px-[5vw]'>
                <div className='max-w-[1200px] w-full flex flex-col items-center '>
                <div className='flex sm:flex-row flex-col justify-center items-center md:mt-28 mt-20 cursor-default'>
        <div className='sm:hidden w-full flex justify-center items-center gap-7'>
            <div className='w-1/2 flex flex-col '>
        <img src={supercool} className='h-auto w-full' alt="super cool song"/>
        <img src={classic} className='h-auto w-full' alt="classic song"/>
        </div>
            <div className='flex flex-col w-1/2'>
                <img src={radio} className='w-full h-auto ' alt="radio song"/>
                <img src={livesong} className='w-full h-auto' alt="80's live song"/>
            </div>
            
          </div>
          <div className='sm:w-1/2 w-full text-white flex flex-col md:gap-[30px] gap-7 mt-10'>
            <h1 className='md:text-[35px] text-[30px] '>
            Press <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Play </span> and <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Relax</span>: Non-Stop <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Radio </span>Everywhere  
            
            </h1>

            <p className='text-[#B4BCD0] text-[16px]'>
            Tune into Soundscape Radio for a steady stream of relaxing and uplifting sounds, perfect for any time. With a mix of music and calming tracks, our radio is great background music to keep you feeling good all day and night. Just hit play and enjoy.
            </p>

           
            <button
  className='px-6 py-2 cursor-pointer  text-[14px] mt-[10px] hover:bg-[#EEEEEE] font-bold rounded-full bg-white shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] text-black w-fit'
  onClick={() => {
    const element = document.getElementById('Hindi Channels'); // Replace 'your-element-id' with the actual id
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }}
>
  Play Now
</button>
          </div>
          <div className='w-1/2  sm:flex hidden justify-center items-center gap-7 mt-5'>
          <div className='flex flex-col '>
          <img src={supercool} className='h-48 w-48' alt="super cool song"/>
        <img src={classic} className='h-48 w-48' alt="classic song"/>
        </div>
            <div className='flex flex-col '>
            <img src={radio} className='w-48 h-48 ' alt="radio song"/>
            <img src={livesong} className='w-48 h-48' alt="80's live song"/>
            </div>
            
          </div>
        </div>
       
        <h2 className='text-center text-[35px] mt-[80px] mb-20'id={"Hindi Channels"}>Radio For <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Uplifting</span> Vibes</h2>
                                        {radioPlaylist && (
                        <div className='mx-24 w-full  flex flex-col gap-20'>
                            {radioPlaylist?.result.map((category, categoryIndex) => (
                                <div key={categoryIndex}>
                                    <div className='font-semibold text-2xl flex items-center' >
                                        <h4 className='mr-3'>{category.category_name}</h4>
                                        <div className=''><LiveIndicator /></div>
                                        <p className='text-[14px] text-[#B4BCD0]'>Live</p>
                                    </div>

                                    <div className='flex justify-between flex-wrap mt-10 w-full'>
                                        {category.audios.slice(0, 3).map((audio, index) => (
                                            <RadioCard
                                                key={index}
                                                id={audio.id}
                                                loading={loading}
                                                image={process.env.REACT_APP_MEDIA_URL + audio.image_path}
                                                songName={audio.audio_name}
                                                audio={categoryIndex===0?audio_urls[index]:audio_urls[3+index]}
                                                onPlayPause={handlePlayPause}
                                                checkPlay={currentAudio === audio.audio_path}
                                                playSongId={songId}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ))}

                        </div>
                    )}

                </div>
                
                </div>
                <FAQSection/>
            </div>
        </div>
    );
};

export default Radio;
