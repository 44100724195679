import React from 'react';

const VideoSection = () => {
  return (
    <section className='flex flex-col items-center bg-[#1F1931]'>
      <div className='mt-32 w-full max-w-[1200px] px-[5vw]'>
        <div className='border border-[#1F4347] w-full h-[550px]  p-2 rounded-md'>
        <iframe
  src="https://www.youtube.com/embed/F7_l28feM5A?autoplay=1&mute=1&loop=1&playlist=F7_l28feM5A&si=TquimDwD0v4JKb90"
  title="YouTube video player"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  referrerPolicy="strict-origin-when-cross-origin"
  allowFullScreen
  className="w-full h-full rounded-md border border-[#1F4347]"
></iframe>

        </div>
      </div>
    </section>
  );
};

export default VideoSection;
