import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/images/Frame 33599.png";
import { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa"; // Import icons for hamburger and close button

const Header = ({ image }) => {
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false); 
    const [isMenuOpenSmall,setIsMenuOpenSmall]=useState(false);
    const[isMenuOpenXtraSmall,setIsMenuOpenXtraSmall]=useState(false);
    const navigate = useNavigate();
    const menu = [
        { name: "Top Playlist", targetId: "top-playlist", path: "/top-playlist" },
        { name: "Moods", targetId: "mood", path: "/moods" },
        { name: "Stories", targetId: "stories", path: "/stories" },
        { name: "Guided Meditations", targetId: "guided-meditations", path: "/guided-meditations" },
        { name: "Brainwaves", targetId: "brainwaves", path: "/brainwaves" },
        { name: "Radio", targetId: "radio", path: "/radio" },
        { name: "Blogs", targetId: "blogs", path: "/blog" },
    ];

    const customStyle = {
        // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(20px)',
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0); 
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleMenuSmall = () => {
        setIsMenuOpenSmall(!isMenuOpenSmall);
    };

    
    const handleExploreClick = () => {
         window.open('https://play.google.com/store/apps/details?id=com.music.focusflow&pcampaignid=web_share','_blank')
    }

    const handleNavigation = (path) => {
        navigate(path);
        window.scrollTo(0, 0);
        setIsMenuOpen(false); // Close the menu after clicking an item
    };

    return (
        <header>
            <div className="relative overflow-y-auto text-[14px] font-bold">
               

                <div className="fixed px-6 md:px-12 flex z-50 items-center justify-between h-auto min-w-full max-w-fit border-b border-[#413563]" style={customStyle}>
                    <img
                        src={logo}
                        alt="logo"
                        className="cursor-pointer py-2 md:py-0  md:w-72  xs:w-56 w-auto"
                        onClick={() => {
                            navigate('/');
                            window.scrollTo(0, 0);
                        }}
                    />

                    {/*Small Screen */}
                    <div className=" xs:pl-4 py-4  justify-between gap-4 flex sm:hidden  ">
                       
                    
                        <button className="px-4 py-1  xs:flex hidden font-bold hover:bg-[#EEEEEE] bg-white w-auto h-auto shadow-[inset_0_-4px_6px_rgba(0,0,0,0.6)] rounded-full text-black" onClick={handleExploreClick}>
                           Download Now
                        </button>
                        <button onClick={toggleMenuSmall} className="text-white xs:ml-4">
                            {isMenuOpenSmall ? <FaTimes size={20} /> : <FaBars size={20} />}
                        </button>
                    </div>

{/*Medium Screen*/}
<div className=" pl-4 py-4  justify-between gap-4 sm:flex md:hidden hidden  ">
                        <div className="bg-[#1D182E] p-[2px] rounded-full hidden sm:flex md:flex justify-end gap-2 text-center ">
                        {menu.slice(0,2).map((item, index) => (
                            <div
                                key={index}
                                onClick={() => handleNavigation(item.path)}
                                className={`cursor-pointer relative flex items-center justify-between px-3 py-3 z-50 ${location.pathname!==item.path?"hover:bg-gradient-to-b from-[#1D182E] via-gray-500 to-[#1D182E] hover:rounded-full rounded":""}`}
                            >

                                <div className="relative text-white">
                                    <span
                                        className={` ${location.pathname === item.path?"bg-white/30 rounded-full p-2 ":""} `}
                                    >
                                        {item.name}
                                    </span>
                                </div>
                            </div>
                        ))}
                        <div
                                
                                onClick={() => handleNavigation("/blog")}
                                className={`cursor-pointer relative flex items-center justify-between px-3 py-3 z-50 ${location.pathname!=='/blog'?"hover:bg-gradient-to-b from-[#1D182E] via-gray-500 to-[#1D182E] hover:rounded-full rounded":""}`}>

                                <div className="relative text-white">
                                    <span
                                        className={` ${location.pathname === menu[6].path?"bg-white/30 rounded-full p-2 ":"hover:font-bold hover:shadow-2xl shadow-gray-400"} `}
                                    >
                                        {menu[6].name}
                                    </span>
                                </div>
                            </div>
                        </div>
                        
                            
                        
                        <button className="px-4 py-1  font-bold hover:bg-[#EEEEEE] bg-white w-auto h-auto shadow-[inset_0_-4px_6px_rgba(0,0,0,0.6)] rounded-full text-black" onClick={handleExploreClick}>
                           Download Now
                        </button>
                        <button onClick={toggleMenu} className="text-white ml-4">
                            {isMenuOpen ? <FaTimes size={20} /> : <FaBars size={20} />}
                        </button>
                    </div>


                    {/* Desktop Menu */}
                    <div className=" pl-4 py-4  justify-between gap-4 hidden md:flex items-center ">
                        <div className="bg-[#1D182E] px-[10px] py-[10px] rounded-full hidden md:flex justify-end gap-2 text-center">
                        {menu.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => handleNavigation(item.path)}
                                className={`cursor-pointer relative flex items-center justify-between px-3 py-3 z-50 ${location.pathname!==item.path?"hover:bg-gradient-to-b from-[#1D182E] via-gray-500 to-[#1D182E] hover:rounded-full rounded":""}`}
                            >

                                <div className="relative text-white  ">
                                    <span
                                        className={` ${location.pathname === item.path?"bg-white/30 text-[14px] rounded-full w-full h-full px-4 py-2":""} `}
                                    >
                                        {item.name}
                                    </span>
                                </div>
                            </div>
                        ))}
                        </div>
                        <button className="px-6 py-4 font-bold text-center  hover:bg-[#EEEEEE] bg-white w-auto h-auto shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] rounded-full text-black" onClick={handleExploreClick}>
                           Download Now
                        </button>
                    </div>
                     
                     {/*Small Screen Menu */}
                     {isMenuOpenSmall && (
                        <div className="absolute top-14 left-0 w-full bg-[#12111B] p-4 md:hidden flex flex-col items-center space-y-2 z-40 sm:hidden max-h-56 overflow-y-auto ">
                            {menu.map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleNavigation(item.path)}
                                    className={`cursor-pointer  text-white w-full text-center py-2  ${location.pathname === item.path ? 'bg-white/30' : 'hover:border hover:px-2 hover:border-white hover:font-semibold hover:rounded-md hover:py-1'}`}
                                 >                                
                             {/* {location.pathname === item.path && ( 
                                     <div className="absolute inset-0 bg-white/30 mt-[22px] rounded-md"></div>
                                )} */}
                                    {item.name}
                                </div>
                            ))}
                        </div>
                    )}


                    {/* Medium Screen Menu */}
                    {isMenuOpen && (
                        <div className="absolute top-20 left-0 w-full bg-[#12111B] p-4 md:hidden sm:flex hidden flex-col items-center space-y-4 z-40 max-h-56 overflow-y-auto">
                            {menu.slice(2,6).map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleNavigation(item.path)}
                                    className={`cursor-pointer  text-white w-full text-center py-2  ${location.pathname === item.path ? 'bg-white/30' : 'hover:border hover:px-2 hover:border-white hover:font-semibold hover:rounded-md hover:py-1'}`}
                                 >                                
                             {/* {location.pathname === item.path && ( 
                                     <div className="absolute inset-0 bg-white/30 mt-[22px] rounded-md"></div>
                                )} */}
                                    {item.name}
                                </div>
                            ))}
                        </div>
                    )}

                    
                </div>
            </div>
        </header>
    );
};

export default Header;
