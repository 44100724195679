// PulsatingCircles.jsx
import React from 'react';
import './LiveIndicator.css'; // Import the CSS for styling

const LiveIndicator = () => {
  return (
    <div className="pulsating-circles">
      <div className="circle circle1"></div>
      <div className="circle circle2"></div>
      <div className="circle circle3"></div>
    </div>
  );
};

export default LiveIndicator;
