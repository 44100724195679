import React from 'react';
import MobilePhone from "../../../assets/images/sleepPhone.svg";
import MobilePhone2 from "../../../assets/images/phonepeace.svg";
import Historical from "../../../assets/images/historicaljourney.svg";
import Fanatsy from "../../../assets/images/fantasy.svg";
import Action from "../../../assets/images/Action.svg";
import grateful from "../../../assets/images/gratefullpeace.svg";
import love from "../../../assets/images/selfLovepeace.svg";
import morning from "../../../assets/images/moringpeace.svg";
import { useNavigate } from 'react-router-dom';

const SongSection = () => {
  const navigate = useNavigate();

  const handleExploreClick = () => {
    window.scrollTo(0, 0);
    navigate("/moods");
  };

  const handleExploreClick2 = () => {
    window.scrollTo(0, 0);
    navigate("/guided-meditations");
  };

  return (
    <div className='flex flex-col items-center px-[5vw] xs:pt-32 pt-20 bg-[#1F1931]'>
      <div className='flex sm:flex-row flex-col w-full max-w-[1200px] gap-10 justify-center items-stretch'>

        {/* Soothing Sleep Stories Card */}
        <div className='flex-1 min-h-[500px] border border-[#1F4347] rounded-[45px] bg-gradient-to-r from-[#0E242C] via-[#193B40] to-[#0E242C] flex flex-col justify-between pl-6 pt-6 xs:pr-0 pr-6'>
          <div className='flex flex-col h-full justify-between'>
            <div className='flex-grow'>
              <h2 className='text-white md:text-[20px] text-[20px]'>Soothing Sleep Stories</h2>
              <p className='mt-2 text-[#B4BAD0] md:text-[14px] text-[14px] pr-[44px]'>
                Find your ideal bedtime companion with sleep stories, thoughtfully collected to calm your mind and lead you into a deep, restorative sleep every night. Each story is tailored to help you relax, let go of the daily stress and drift into a peaceful sleep.
              </p>
            </div>

            <button
              className='self-start px-6 py-2 cursor-pointer md:text-[14px] text-[14px] shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] hover:bg-[#EEEEEE] font-bold rounded-full bg-white text-black mt-6'
              onClick={handleExploreClick}
            >
              Explore Now!
            </button>
          </div>

          <div className='flex justify-end items-end gap-5 xs:pr-0'>
            <div className='flex gap-6 pb-4 justify-end items-center'>
              <div className='flex flex-col gap-3'>
                <img src={Historical} alt="Historical stories" className='w-32 h-32 object-contain' />
              </div>
              <div className='flex flex-col gap-1'>
                <img src={Fanatsy} alt="Fantasy" className='w-32 h-32 object-contain' />
                <img src={Action} alt="Action" className='w-32 h-32 object-contain' />
              </div>
            </div>
            <div className='w-1/3 xs:flex hidden'>
              <img src={MobilePhone} alt="phone" className="sm:w-full xs:w-auto h-auto object-cover" />
            </div>
          </div>
        </div>

        {/* Peaceful Guided Meditation Card */}
        <div className='flex-1 min-h-[500px] border border-[#1F4347] rounded-[45px] bg-gradient-to-r from-[#0E282C] via-[#193B40] to-[#0E282C] flex flex-col justify-between pl-6 pt-6 xs:pr-0 pr-6'>
          <div className='flex flex-col h-full justify-between'>
            <div className='flex-grow'>
              <h2 className='text-white text-[20px]'>Peaceful Guided Meditation</h2>
              <p className='mt-2 text-[#B4BAD0] md:text-[14px] text-[14px] pr-[44px]'>
                Discover your inner calm with guided meditations. Explore a variety of themes and techniques to find the perfect practice for your needs. Reduce stress and find balance with guided meditations that help you relax and focus on what matters.
              </p>
            </div>

            <button
              className='self-start px-6 py-2 cursor-pointer md:text-[14px] text-[14px] shadow-[inset_0_-4px_6px_rgba(0,0,0,0.3)] hover:bg-[#EEEEEE] font-bold rounded-full bg-white text-black mt-6'
              onClick={handleExploreClick2}
            >
              Explore Now!
            </button>
          </div>

          <div className='flex justify-end items-end gap-5 xs:pr-0'>
            <div className='flex justify-end items-center gap-3 pb-6'>
              <div className='flex flex-col'>
                <img src={grateful} alt="grateful" className='w-32 h-[112px] object-contain' />
              </div>
              <div className='flex flex-col gap-4'>
                <img src={love} alt="love" className='w-32 h-[112px] object-contain' />
                <img src={morning} alt="morning" className='w-32 h-[112px] object-contain' />
              </div>
            </div>
            <div className='w-1/3 xs:flex hidden'>
              <img src={MobilePhone2} alt="phone" className="sm:w-full xs:w-auto h-auto object-cover" />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default SongSection;
