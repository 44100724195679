import React from 'react'
import Header from './Header'
import image from "../assets/images/Vector 228.png"
import Card1 from "../assets/images/Vector 227.png"
import Card2 from "../assets/images/Vector 227 (1).png"
import Card3 from "../assets/images/Vector 230.png"
import feature1 from "../assets/images/Group 7392.png"
import feature2 from "../assets/images/Group 7393.png"
import feature3 from "../assets/images/Group 7394.png"
import feature5 from "../assets/images/Group 7395.png"
import feature6 from "../assets/images/Group 7396.png"
import feature4 from "../assets/images/Group 7397.png"
import AboutCard from './AboutCard'
import Line from "../assets/images/Group 7422.svg";
import missonImage from "../assets/images/Group 7534.svg"
import musicIcon from "../assets/images/music-note 1.svg"
import brainwaves from "../assets/images/brainwaves (1) 1.webp";
import guided from "../assets/images/lotus (1) 1.webp";
import radio from "../assets/images/radio (2) 1.webp";
import moon from "../assets/images/moon 1.webp";
import blogImage from "../assets/images/blogging 1.svg";
const AboutUs = () => {
    const cards=[{
        image:Card1,
        title:"Who We Are",
        des:"Founded in 2023, Soundscape offers curated nature sounds, ambient melodies, and calming tones to bring focus, balance, and peace in a hectic world."
    },
    {
        image:Card2,
        title:"What We Do?",
        des:"We have created a library of Solfeggio frequencies, Binaural beats, ambient melodies, and calming tones to help you relax, focus and get better sleep through a hectic schedule. "
    },
    {
        image:Card3,
        title:"How We Do It?",
        des:"Life can be overwhelming. Finding the right sounds shouldn’t be. Soundscape offers curated playlists for every mood, live radio, and calming nature sounds. Enhance your "
    }];
    const features=[
        {
            image:feature1,
            title:"Soundscapes",
            des:"A library of soothing sounds for your daily needs and different moods."
        },
        {
            image:feature2,
            title:"Stories",
            des:"A collection of stories that are perfect for unwinding and falling asleep."
        },
        {
            image:feature3,
            title:"Live Radio",
            des:"A library of soothing sounds for your daily needs and different moods."
        },
        {
            image:feature4,
            title:"Brainwaves",
            des:"Our collection of different solfeggio frequencies and binaural beats."
        },
        {
            image:feature5,
            title:"Guided Meditations",
            des:"Our easy guided meditations to relax and release stress."
        },
        {
            image:feature6,
            title:"Blogs",
            des:"Check out our blogs for helpful Tips and Ideas on how to boost your mood."
        },
    ]
  return (
    <div className='text-white relative '>
    <div className=' w-full bg-gradient-to-b' style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, rgba(31, 25, 49, 1) 20%, #1F1931 100%)' }}>
        <Header/>
        

        <div className='flex flex-col items-center px-[5vw]'>
        
        <div className='max-w-[1200px] w-full mb-32 min-h-screen max-h-full '>

        <div className="relative">
  {/* Image Section */}
  <div className="mt-32">
    <img src={image} alt="About Us" className="w-full h-auto object-cover" />
  </div>

  {/* Text Section */}
  <div className="absolute sm:flex flex-col md:top-24 top-8 hidden right-[5%] left-[38%] text-start">
    <h1 className="font-bold text-4xl ">
      About Us
    </h1>
    <p className="text-lg text-justify mt-4 text-[#B4BCD0]">
      Soundscape offers a collection of sounds and frequencies to make you feel
      more relaxed and focused with our easy-to-use app whenever you need it.
    </p>
  </div>
</div>
        <div className='flex flex-wrap justify-around items-center gap-5 mt-20'>
        {cards.map((i)=>{
           return <AboutCard data={i} />
        })}
        </div>

        <div className='mt-14 flex flex-col justify-center items-center text-center text-[20px]'>
            <img src={missonImage} alt="misson image" className='h-72'/>
         <p className='-mt-10 text-[#B4BCD0]'>Multiple researches have proven that sound has the power to change moods, enhance focus, and help relax. We are committed to providing a unique and relaxing experience for everyone in their daily routine. We have designed a customized sound experience to meet your specific needs Whether it's easing stress, improving mood, or enhancing sleep quality, the soundscape is available to assist everyone in their journey to better health with sounds.</p>
         <p className='mt-7 text-[#B4BCD0]'>Join us at Soundscape and discover the perfect soundtrack for your life.</p>
       
       </div>
       <div className="flex sm:flex-row flex-col justify-center text-[15px] text-[#B4BCD0] mt-20 relative">
  {/* Section 1 */}
  <div className="flex  gap-8">
    <img 
      src={Line} 
      alt="line image" 
      className="h-auto self-stretch" 
    />
    <div className="flex flex-col gap-20 my-24 ">
      <div className='pr-4'>
        <div className="flex gap-5 mb-2 text-[20px] text-white">
          <p>Soundscapes</p>
          <img src={musicIcon} alt="soundscape music icon" className="w-8 h-8 -mt-2" />
        </div>
        <p>Enhance your rest with brainwaves, guided meditations, soothing sleep music, and calming stories.</p>
      </div>
      <div className='pr-10'>
        <div className="flex gap-5 mb-2 text-[20px] text-white">
          <p>Guided Meditations</p>
          <img src={guided} alt="guided meditation icon" className="w-8 h-8 -mt-2" />
        </div>
        <p>Our easy guided meditations to relax and release stress</p>
      </div>
    </div>
  </div>

  {/* Section 2 */}
  <div className="flex gap-8">
    <img 
      src={Line} 
      alt="line image" 
      className="h-auto self-stretch" 
    />
    <div className="flex flex-col gap-20 my-24 pr-10">
      <div>
        <div className="flex gap-5 mb-2 text-[20px] text-white">
          <p>Live Radio</p>
          <img src={radio} alt="radio icon" className="w-8 h-8 -mt-2" />
        </div>
        <p>Enjoy live music according to your preference without any interruptions</p>
      </div>
      <div>
        <div className="flex gap-5 mb-2 text-[20px] text-white">
          <p>Stories</p>
          <img src={moon} alt="moon icon" className="w-8 h-8 -mt-2" />
        </div>
        <p>A collection of stories that are perfect for unwinding and falling asleep</p>
      </div>
    </div>
  </div>

  {/* Section 3 */}
  <div className="flex gap-8">
    <img 
      src={Line} 
      alt="line image" 
      className="h-auto self-stretch" 
    />
    <div className="flex flex-col gap-20 my-24 pr-10">
      <div>
        <div className="flex gap-5 mb-2 text-[20px] text-white">
          <p>Brainwaves</p>
          <img src={brainwaves} alt="brainwaves icon" className="w-10 h-10 -mt-2" />
        </div>
        <p>Our collection of different solfeggio frequencies and binaural beats</p>
      </div>
      <div>
        <div className="flex gap-5 mb-2 text-[20px] text-white">
          <p>Blogs</p>
          <img src={blogImage} alt="blog icon" className="w-8 h-8 -mt-2" />
        </div>
        <p>Check out our blogs for helpful tips and ideas on how to boost your mood</p>
      </div>
    </div>
  </div>

  
</div>


       </div>
       </div>
       </div>
       
    </div>
  )
}

export default AboutUs