import React from 'react'
const AboutCard = ({data}) => {
    // console.log(image);
  return (
    <div className='w-80 h-auto border border-[#413563] bg-gradient-to-r from-[#161224] via-[#312B43] to-[#171325] rounded-2xl p-4'>
        <div className='flex flex-col gap-7'>
        <img src={data.image}  className='h-16 w-32'/>
        <div className=''>
       
        <h3 className='text-[20px] font-semibold'>{data.title}</h3>
      
      
        <p className='text-justify text-[15px] mt-2 text-[#B4BCD0]'>{data.des}</p>
</div>
    </div>
    </div>
  )
}

export default AboutCard