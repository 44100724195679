import React ,{useState,useEffect}from 'react';
import GuidedPlayer from './GuidedPlayer';


const GuidedSection = () => {
    const [playingTrack, setPlayingTrack] = useState(null); 
    const [isPlaying, setIsPlaying] = useState(false);
    const [playlist,setPlayinglist]=useState(null);
    const width = "sm:w-full w-[300px] md:h-64 sm:h-52";
    const data = [
        {
            image: process.env.REACT_APP_MEDIA_URL+playlist?.result[0].image_path,
            title: playlist?.result[0].audio_name,
            duration:"03:55",
            song:process.env.REACT_APP_MEDIA_URL+playlist?.result[0].audio_path,
            About: "Take a moment to relax and be present. This guided meditation helps you let go of stress and anxiety, bringing peace to your mind. With soothing sounds and gentle rhythms, it encourages you to focus and feel calm. It's perfect for anyone wanting to stay centred and enjoy the moment."
        },
        {
            image: process.env.REACT_APP_MEDIA_URL+playlist?.result[1].image_path,
            title: playlist?.result[1].audio_name,
            song:process.env.REACT_APP_MEDIA_URL+playlist?.result[1].audio_path,
            duration:"03:00",
            About: "Celebrate who you are with this nurturing experience. This session helps you embrace self-acceptance and let go of self-doubt. With soothing sounds, it deepens your connection to your worth and encourages self-care. It’s a reminder to love and appreciate yourself every day."
        },
        {
            image: process.env.REACT_APP_MEDIA_URL+playlist?.result[2].image_path,
            title: playlist?.result[2].audio_name,
            duration:"03:11",
            song:process.env.REACT_APP_MEDIA_URL+playlist?.result[2].audio_path,
            About: "Take time to appreciate the good things in your life. This meditation encourages you to reflect on what you're thankful for, helping to release stress and foster feelings of joy. With soothing sounds, it reminds you to cherish the blessings around you and cultivate a grateful heart."
        },

    ];
console.log(process.env.REACT_APP_MEDIA_URL+playlist?.result[0].image_path);
    const handleTrackClick = (songName) => {
        if (playingTrack === songName) {
            // If the track is already playing, pause it
            setIsPlaying(false); 
        } else {
            // If another track is clicked, start playing it
            setPlayingTrack(songName); 
            setIsPlaying(true); 
        }
    };

    useEffect(() => {
        if (!isPlaying) {
            setPlayingTrack(null);
        }
    }, [isPlaying]);

    const fetchData=async()=>{
        const data=await fetch(process.env.REACT_APP_BACKEND_URL+"/web_guided-meditations");
        const result=await data.json();
        console.log(result);
        setPlayinglist(result);
    }
    
     useEffect(()=>{
       fetchData();
     },[playlist])
       
     
    
    return (
        

            
        <div className='pt-[80px]  items-center flex flex-col px-[5vw] relative'>
        <div className='w-full max-w-[1200px] relative text-white'>
<h2 className='text-center text-[35px] ' id="Mindfulness">Meditations For Inner <span className=' bg-gradient-to-r from-[#7EC7D5] to-[#4032A1] text-transparent bg-clip-text font-bold'>Peace</span></h2>
            
                {playlist&&data.map((item, index) => (
                    <GuidedPlayer
                        key={index} 
                        image={item.image} 
                        name={item.title} 
                        About={item.About} 
                        width={width}
                        duration={item.duration}
                        songs={item.song}
                        onPlayTrack={handleTrackClick}
                            isPlaying={playingTrack === item.title && isPlaying} 
                            setIsPlaying={setIsPlaying} 
                        
                    />
                ))}
                </div>

            
            </div>
            

    );
};

export default GuidedSection;
