import React from 'react'
import HomeSection from './Sections/HomeSection'
import Header from '../Header'
import MoodSection from './Sections/MoodSection'
import FeatureSection from './Sections/FeatureSection'
import SongSection from './Sections/SongSection'
import SongSection2 from './Sections/SongSection2'
import VideoSection from './Sections/VideoSection'
import SliderSection from './Sections/SliderSection'
import FAQSection from './Sections/FAQSection'
import ReviewSection from './Sections/ReviewSection'

const LandingPage = ({combinedData}) => {
  return (
  // <div className="bg-gradient-to-b" style={{  backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 2%, #1F1931 2%)' }}>
  <div className="bg-gradient-to-b" style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(31, 25, 49, 1) 5%, #1F1931 100%)' }}>


       <Header/>
<HomeSection/>
<div className='bg-[#1F1931]'>
<MoodSection/>
<FeatureSection/>
<SongSection/>
<VideoSection/>
<SongSection2/>
<SliderSection items={combinedData}/>
<ReviewSection/>
<FAQSection/>
</div>

    </div>
  )
}

export default LandingPage